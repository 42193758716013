// Font family
$font-stack: Arial, Helvetica, sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
// Google font ballback

.wf-active body {
  opacity: 1;
}
// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(26);
  line-height: 1.2em;
  font-weight: 700;
}

.h-like-b {
  font-size: px(22);
  line-height: 1.3em;
  font-weight: 700;
  text-transform: uppercase;
  &.bordered {
    font-size: px(24);
  }
  &.bordered:after {
    content:"";
    display: block;
    margin-top: px(4);
    width: px(30);
    height: px(3);
    margin-left: px(2);
    background-color: $bk-a;
  }
}

.h-like-c {
  font-size: px(14);
  line-height: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
}
.h-like-d {
  font-size: px(28);
  line-height: 1.2em;
  font-weight: 700;
}
.h-like-e {
  font-size: px(22);
  line-height: 1.2em;
  font-weight: 700;
}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: px(22);
  }
  .h-like-b.bordered {
    font-size: px(22);
  }
  .h-like-d {
    font-size: px(26);
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: px(20);
  }
  .h-like-b.bordered {
    font-size: px(20);
  }
  .h-like-d {
    font-size: px(24);
  }

  .h-like-b {
    font-size: px(18);
  }

  .h-like-c {
    font-size: px(13);
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
