// Footer

#footer {
	color: $wh-a;

	.payment-wp {
		position: absolute;
		bottom: px(-5);
		left: px(25);
		.title {
			font-size: px(11);
			margin-bottom: px(5);
		}
		@include media-breakpoint-down(md) {
			bottom: 0;
		}
		@include media-breakpoint-down(sm) {
			right: 0;
			top: px(25);
			left: auto;
			bottom: auto;
		}
		@include media-breakpoint-down(xs) {
			right: 0;
			bottom: auto;
			text-align: center;
			left: 0;
			top: auto;
			bottom: px(-65);
		}
	}

	#footer-top {
		position: relative;
		background-color: $rd-a;
		padding: px(50) 0;
		.container {
			position: relative;
			min-height: 150px;
		}
		.txt {
			font-size: px(13);
		}
		@include media-breakpoint-down(md) {
			padding: px(35) 0;

			.txt {
				font-size: px(12);
			}
		}
		@include media-breakpoint-down(sm) {
			padding: px(15) 0 px(30);

			.logo {
				display: block;
				width: px(250);
				margin-bottom: px(10);
			}

			.txt {
				font-size: px(13);
			}
		}
		@include media-breakpoint-down(xs) {
			padding: px(0) 0 px(90) px(0);
		}
	}

	#footer-bottom {
		background-color: $bk-a;
		padding: px(15) 0;

		.link {
			font-size: px(12);
		}

		.list {
			text-align: center;

			li {
				display: inline-block;
				margin: 0 px(10);
			}
		}

		a.link:focus,
		a.link:hover {
			text-decoration: underline;
		}
		@include media-breakpoint-down(lg) {
			.list {
				border-bottom: 1px solid rgba(255,255,255,0.2);
				padding-bottom: px(10);
				text-align: left;

				li:first-child {
					margin-left: 0;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.list {
				text-align: center;
				position: relative;

				.link {
					font-size: px(13);
					text-align: center;
				}
			}

			.link {
				font-size: px(11);
			}
		}
		@include media-breakpoint-down(xs) {
			padding: px(10) 0;

			.list {
				padding-bottom: px(5);
				li {
					margin: 0 px(5) !important;
				}

				.link {
					text-align: left;
					font-size: px(12);
				}
			}
		}
	}
}
