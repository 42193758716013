.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	@include media-breakpoint-down(md) {
		height: 250px;
	}
	@include media-breakpoint-down(sm) {
		height: 200px;
	}
}

.item-contact {
	.title-contact {
		position: relative;
		color: $rd-a;
		padding-left: 23px;
		font-weight: 700;
		font-size: px(14.5);
	}

	.title-contact .material-icons {
		position: absolute;
		top: 1px;
		left: 0;
		font-size: px(18);
	}

	.list {
		margin-top: 8px;
		padding-left: 25px;
	}

	.list li {
		display: block;
		position: relative;
		padding-left: 11px;
		font-size: px(13.5);
		line-height: 1.4em;
		word-break: break-word;
	}

	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: "";
		position: absolute;
		top: 9px;
		left: 0;
		width: 4px;
		height: 1px;
		background-color: $gy-a;
	}
	@include media-breakpoint-down(sm) {
	display: inline-block;
		width: 49.5%;
		vertical-align: top;
	}
	@include media-breakpoint-down(xs) {
		float: block;
		width: 100%;
	}
}
