// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: block !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;
}

#header-top {
	.lang-wp {
		display: inline-block;
		vertical-align: middle;
		margin-right: px(30);

		li {
			display: inline-block;

			a {
				text-transform: uppercase;
				transition: all 0.2s ease-in-out;

				&.active,
				&:focus,
				&:hover {
					color: $rd-a;
				}
			}

			&:first-child:after {
				content: "-";
			}
		}
	}

	.btn-account {
		vertical-align: middle;
		display: inline-block;
		background-color: $rd-a;
		color: $wh-a;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		padding: px(7) px(25) px(8);
		transition: all 0.2s ease-in-out;

		&:focus,
		&:hover {
			background-color: shade($rd-a, 10%);
		}
	}
	@include media-breakpoint-down(lg) {
		.logo {
			display: block;
			width: px(300);
		}
	}
	@include media-breakpoint-down(md) {
		.logo {
			width: px(250);
		}
	}
	@include media-breakpoint-down(sm) {
		.logo {
			width: px(170);
		}

		.lang-wp {
			margin-right: px(20);
		}

		.btn-account {
			font-size: px(12);
		}
	}
	@include media-breakpoint-down(xs) {
		height: px(80);
		padding-top: px(10);

		.logo {
			width: px(150);
		}

		.lang-wp {
			position: absolute;
			top: px(20);
			right: px(60);
		}

		.btn-account {
			display: none;
		}

		#nav-mobile-trigger {
			background-color: $rd-a;
			position: absolute;
			top: px(15);
			right: px(15);
			width: px(50);
			height: px(50);
		}
	}
}

#header-bottom {
	margin-top: px(40);
	@include media-breakpoint-down(sm) {
		margin-top: px(20);
	}
	@include media-breakpoint-down(xs) {
		margin-top: 0;
	}

	#nav-main {
		display: block;
		width: 100%;
		text-align: right;
		position: relative;

		.item {
			display: inline-block;
		}

		.link {
			display: block;
			color: $bk-a;
			font-size: px(18);
			padding: px(6) px(25);
			transition: all 0.2s ease-in-out;

			&:focus,
			&:hover {
				color: $rd-a;
			}

			&.active {
				background-color: $rd-a;
				color: $wh-a;
			}
		}
		@include media-breakpoint-down(md) {
			.link {
				font-size: px(17);
				padding: px(6) px(20);
			}
		}
		@include media-breakpoint-down(sm) {
			.link {
				font-size: px(14);
				padding: px(6) px(12);
			}
		}
		@include media-breakpoint-down(xs) {
			background-color: $gy-a;
			padding: px(10) 0;
			display: none;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background-color: $gy-a;
				width: 200%;
				height: 100%;
				margin-left: -50%;
				background-color: $gy-a;
			}

			.item {
				position: relative;
				z-index: 2;
				display: block;
				text-align: center;
			}

			.link {
				font-size: px(17);
				padding: px(10) px(15);
			}
		}
	}
}

// Header page
#header-page {
	background-color: $rd-a;
	color: $wh-a;
	text-align: center;
	padding: px(35) 0;
	.title {
		text-transform: uppercase;
		font-weight: 700;
		font-size: px(26);
	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(25);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
		.title {
			font-size: px(24);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(25) 0;
		.title {
			font-size: px(22);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;
		.title {
			font-size: px(20);
		}
	}
}
