// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	transition: all 0.2s ease-in-out;
	display: inline-block;
	font-weight: 700;
	font-size: px(13);
	text-transform: uppercase;
	text-align: center;
	padding: px(8) px(25) px(8);
	border-style: solid;
	border-width: 2px;
	transition: all 0.2s ease-in-out;
	&.small {
		font-size: px(11);
		padding: px(10) px(10) px(10);
		font-weight: 400;
	}
	&.white {
		background-color: $wh-a;
		color: $rd-a;
		border-color: $wh-a;
		&:hover,
		&focus {
			background-color: transparent;
			color: $wh-a;
		}
	}
	&.red {
		background-color: $rd-a;
		color: $wh-a;
		border-color: $wh-a;
		&:hover,
		&focus {
			background-color: $wh-a;
			color: $rd-a;
			border-color: $rd-a;
		}
	}
	&.black {
		background-color: $bk-a;
		color: $wh-a;
	}

	&:focus,
	&:hover {}
	&.except-padding {
		padding: px(8) px(15) px(8);

	}
}

.btn-b {
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	padding: 0 px(35) 0 0;
	font-size: px(14);
	.icon {
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: px(-12);
		width: px(24);
		height: px(24);
		border-style: solid;
		border-width: 1px;
		border-radius: 25px;
		text-align: center;
		transition: all 0.1s ease-in-out;
	}
	&.black {
		color: $bk-a;
		.icon {
			border-color: $bk-a;
			background-color: $bk-a;
		}
	}
	&.white {
		color: $wh-a;
		.icon {
			border-color: $wh-a;
			background-color: transparent;
		}
	}
	&:hover,
	&:focus {
		.icon {
			right: px(3);
		}
	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 25px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}
