@media print {
	// Image :: Disable full width
	img.w-full,
	img.w-full-lg,
	img.w-full-md,
	img.w-full-sm,
	img.w-full-xs {
		width: auto !important;
		margin: 0 auto !important;
	}
	// Buttons :: Hide
	.btn,
	.pager,
	.pagination {
		display: none !important;
	}
	// Slick reset
	.slick-list.draggable {
		height: auto !important;
	}

	.slick-initialized .slick-slide,
	.slick-track {
		width: 100% !important;
		left: auto !important;
	}

	.slick-initialized .slick-slide {
		margin-bottom: 15px !important;
	}

	.slick-cloned {
		display: none !important;
	}
	// Parralax
	.parallax-mirror {
		display: none !important;
	}
	// Wow Plugin :: Display Correctly
	.wow,
	.wow * {
		opacity: 1 !important;
		visibility: visible !important;
	}
	// Link href :: Hide
	a:link:after,
	a:visited:after {
		content: " (" attr(href) ")";
		display: none !important;
	}
}
