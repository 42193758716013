.item-blog {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  background-color: $wh-a;
  padding: px(20);
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
  }

  img {
    border-radius: 3px;
  }

  .category {
    border: 2px solid $rd-a;
    text-transform: uppercase;
    color: $wh-a;
    background-color: $rd-a;
    display: inline-block;
    font-size: px(10);
    padding: px(1) px(6);
    font-weight: 700;
    border-radius: 4px;
    margin-top: px(15);
  }

  .title {
    font-size: px(20);
    font-weight: 500;
    padding-top: px(15);
    font-weight: 700;
    line-height: 1.2em;
  }
  .desc {
    line-height: 1.3em;
    font-size: px(14);
    margin-top: px(8);
  }
  .btn-wp {
    float: right;
    color: $rd-a;
    text-decoration: underline;
    font-size: px(13);
    font-weight: 700;
  }
  @include media-breakpoint-down(lg) {
    .title {
      font-size: px(19);
    }
  }
  @include media-breakpoint-down(md) {
    .title {
      font-size: px(18);
    }
  }
  @include media-breakpoint-down(xs) {
    .title {
      font-size: px(17);
    }
  }
}

#slick-blog-wp {
  position: relative;
  padding-top: px(50);
  width: calc(100% + 20px);
  margin-left: px(-10);

  .slick-slide {
    outline: none !important;
  }
  .item {
    padding: px(10);
  }
  .link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $rd-a;
    font-weight: 700;
    border-radius: 30px;
    border: 2px solid $rd-a;
    height: px(55);
    font-size: px(14.5);
    line-height: 1.15em;
    padding: px(5) px(20);
    outline: none !important;
    text-align: center;
    transition: all 0.2s ease-in-out;
    &:hover,
    &.active {
      background-color: $rd-a;
      color: $wh-a;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: px(40);
    .link {
      font-size: px(13.5);
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: px(30);
    .link {
      font-size: px(14.5);
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: px(15);
    .link {
      font-size: px(13);
    }
  }

  .slick-dots {
    margin-top: px(5);
    margin-bottom: 0;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 2px;
    }

    button {
      width: px(10);
      height: px(10);
      margin-right: 5px;
      border-radius: 100%;
      background-color: shade($gy-a, 20%);
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      text-indent: -999em;
    }

    .slick-active button {
      background-color: $rd-a;
    }

    button:focus,
    button:hover {
      background-color: $rd-a;
    }

  }

}

// Pagination
.pagination {
  justify-content: center;
  margin-top: 0;

  .page-link {
    color: $rd-a;
    height: 35px;
  }

  .page-link.active {
    background-color: $rd-a !important;
    color: $wh-a !important;
    border-top-color: $rd-a;
    border-bottom-color: $rd-a;
    font-weight: 400;
  }

  .page-item.link-arrow .page-link {
    padding: px(6) px(12);
    background-color: tint($gy-a, 30%);
    color: $gy-a;
    transition: all 0.2s ease-in-out;
  }

  .page-item.link-arrow .page-link:focus,
  .page-item.link-arrow .page-link:hover {
    background-color: $rd-a;
    color: $wh-a;
  }

  .page-item .page-link:focus,
  .page-item .page-link:hover {
    background-color: tint($rd-a, 90%);
  }
}
