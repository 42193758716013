#resa-form-wp {
  background-color: $rd-a;
  color: $wh-a;
  padding: px(30) 0;

  .checkbox-wp {
    position: relative;
    display: block;

    > input,
    > label {
      cursor: pointer;
    }

    > input {
      position: absolute;
      -moz-opacity: 0;
      opacity: 0;
      filter: alpha(opacity = 0);
    }

    > input[type=checkbox] + label {
      display: block;
      padding: px(0) 0 px(1) px(28);
      font-size: px(13);
      color: $wh-a;
      font-weight: 300;
      background-image: url("../images/icons/sprite-checkbox.png");
      line-height: 1.3em;
      background-repeat: no-repeat;
      background-size: 17px;
    }

    > input[type=checkbox] + label {
      background-position: 0 -185px;
    }

    > input[type=checkbox]:checked + label {
      background-position: 0 0;
    }
  }

  .input-form {
    background-color: transparent;
    line-height: 1.2em;
    display: block;
    border: 2px solid $wh-a;
    width: 100%;
    padding: px(8) px(10);
    color: $wh-a;
    height: px(38);

    &::placeholder {
      color: $wh-a;
      opacity: 1;
    }
  }

  .datepicker-wp {
    position: relative;

    &.open {
      .arrow-white {
        transform: rotate(-90deg) !important;
      }
    }

    .input-form {
      padding-right: px(65);
    }

    .ui-datepicker-trigger {
      content: "";
      position: absolute;
      top: px(-3);
      right: px(-5);

      .picker-date {
        transform: scale(.6) translateX(-15px);
      }

      > * {
        display: inline-block;
        vertical-align: middle;

        &:first-child {
          margin-right: px(12);
        }
      }

      .arrow-white {
        position: relative;
        top: px(2);
        transform: rotate(90deg);
      }
    }
  }

  .selectpicker-wp {
    position: relative;

    .bootstrap-select {
      width: 100% !important;
    }

    .filter-option-inner-inner {
      display: block;
      @include ellipsis;
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: px(65) !important;
      @extend .input-form;
      outline: none;
    }

    .dropdown-toggle:focus {
      outline: none !important;
    }

    .dropdown-toggle:after {
      position: absolute;
      top: px(7);
      right: px(40);
      border: none;
      @extend .sprite;
      @extend .arrow-white;
      transform: rotate(90deg) scale(1);
    }

    .bootstrap-select.show .dropdown-toggle:after {
      transform: rotate(-90deg) scale(1);
    }

    &.airport,
    &.hour {
      .dropdown-toggle:before {
        content: "";
        position: absolute;
        top: px(-3);
        right: px(2);
        border: none;
        @extend .sprite;
        transform: scale(.55);
      }
    }

    &.airport {
      .dropdown-toggle:before {
        @extend .picker-place;
      }
    }

    &.hour {
      .dropdown-toggle:before {
        @extend .picker-time;
      }
    }
  }

  .btn-a {
    display: block;
    width: 100%;
  }
  @include media-breakpoint-down(xs) {
    padding: px(15) 0;

    .selectpicker-wp {
      .arrow-white {
        display: none;
      }

      .dropdown-toggle {
        padding-right: px(45) !important;
      }
    }

    .datepicker-wp {
      .input-form {
        padding-right: px(45);
      }

      .arrow-white {
        display: none;
      }

      .ui-datepicker-trigger {
        right: px(-5);
      }
    }
  }
}

#resa-page-wp {
  background-color: shade($rd-a, 20%);
  box-shadow: -1px 0px 6px inset rgba(0, 0, 0, 0.1);
  &.except-color {
    background-color: $rd-a;
  }
  #resa-form-wp {
    background-color: transparent;
  }
}
