#slick-home-wp {
  position: relative;

  .slick-slide {
    outline: none !important;
  }

  .slick-slide .col-car {
    opacity: 0;
    transform: translateX(100px) scale(1);
    transition: all 0.2s ease-in-out;
  }

  .slick-slide.animation .col-car {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .slick-slide .col-price .content-wp {
    opacity: 0;
    transform: translateX(0) scale(0.8);
    transition: all 0.2s ease-in-out 0.2s;
  }

  .slick-slide.animation .col-price .content-wp {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .slick-dots {
    margin-top: 0;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 2px;
    }

    button {
      width: px(60);
      height: px(4);
      margin-right: 5px;
      background-color: $bk-a;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      text-indent: -999em;
    }

    .slick-active button {
      background-color: $rd-a;
    }

    button:focus,
    button:hover {
      background-color: $rd-a;
    }
    @include media-breakpoint-down(sm) {
      button {
        width: px(40);
      }
    }
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: px(350);
    width: 100%;
    background-image: url("../images/illustrations/bg-reservation.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .item {
    width: 100%;
    height: px(550);
    position: relative;

    .col-price {
      padding: px(40) px(20) px(20);
      background-color: $wh-a;
      height: px(350);
      width: px(300);

      .title {
        @extend .h-like-a;
        margin-bottom: px(5);
      }

      .category {
        font-size: px(14);
        opacity: 0.8;
      }

      .price-wp {
        margin-top: px(15);
        color: $rd-a;

        .txt-large {
          font-size: px(28);
        }
      }

      .btn-wp {
        margin-top: px(15);

        .btn-a {
          padding-left: px(40);
          padding-right: px(40);
        }
      }
    }

    .col-car {
      position: absolute;
      top: px(80);
      right: px(50);
      width: calc(100% - 250px);
    }
  }
  @include media-breakpoint-down(lg) {
    .item {
      height: px(480);

      .col-car {
        top: px(100);
        right: px(20);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .slick-list {
      overflow: visible;
    }

    .item {
      height: px(450);

      .col-car {
        width: calc(100% - 100px);
        top: px(120);
        right: px(-120);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &:before {
      height: px(270);
    }

    .item {
      height: px(380);

      .col-price {
        padding: px(30) px(20) px(20);
        width: px(270);

        .price-wp {
          margin-top: px(5);
        }

        .btn-wp {
          margin-top: px(5);

          .btn-a {
            font-size: px(12);
            padding-left: px(15);
            padding-right: px(15);
          }
        }
      }

      .col-car {
        width: calc(100% - 40px);
        top: px(120);
        right: px(-120);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .item {
      height: auto;
      .col-price {
        padding: px(20);
        width: 100%;
        position: relative;

        .btn-wp {
          display: none;
        }
      }

      .col-car {
        width: calc(100% - 15px);
        top: auto;
        bottom: px(10);
        right: px(-55);
        .btn-mobile {
          text-align: center;
          margin-left: px(-125);
          .btn-a {
            font-size: px(13);
          }
        }
      }
    }
    #slick-home-arrows {
      .arrow-prev,
      .arrow-next {
        position: absolute;
        bottom: px(10);
      }
      .arrow-prev {
        left: px(25);
      }
      .arrow-next {
        right: px(25);
      }
    }
  }
}

#home-grid-wp {
  .col-md-6 {
    overflow: hidden;
  }
  .item-img {
    width: 100%;
    height: px(350);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &.small {
      height: px(200);
    }
    &.big {
      height: px(400);
    }
  }
  .item-title {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: px(200);
    padding: px(0) px(70);
  }
  .item-link {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    height: px(200);
    padding: px(0) px(30) px(30) px(30);
    .txt-big {
      display: block;
      font-size: px(19);
    }
  }
  @include media-breakpoint-down(lg) {
    .item-title {
      padding: 0 px(50);
    }
    .h-like-b {
      font-size: px(20);
    }
    .h-like-d {
      font-size: px(26);
    }
    .item-link {
      .txt-big {
        font-size: px(17);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .item-img {
      height: px(200);
    }
    .h-like-b {
      font-size: px(18);
    }
    .h-like-d {
      font-size: px(24);
    }
    .item-title {
      padding: 0 px(30);
    }
    .item-link {
      padding: px(20);
      .txt-big {
        font-size: px(14);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .item-title {
      height: px(150);
      padding: px(0) px(40);
    }
    .item-img {
      &.big {
        height: px(250);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .item-title {
      height: px(120);
      padding: px(0) px(20);
    }
    .item-img,
    .item-link {
      height: px(135) !important;
    }
  }
}
