.item-services {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  background-color: $wh-a;
  padding: px(20);
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 5px;

  img {
    border-radius: 3px;
  }

  .title {
    font-size: px(20);
    font-weight: 500;
    padding-top: px(15);
    font-weight: 700;
    line-height: 1.2em;
  }
  .desc {
    line-height: 1.3em;
    font-size: px(14);
    margin-top: px(8);
  }
  .btn-a {
    padding: px(7) px(15);
    font-size: px(11);
  }
  @include media-breakpoint-down(lg) {
    .title {
      font-size: px(19);
    }
  }
  @include media-breakpoint-down(md) {
    .title {
      font-size: px(18);
    }
  }
  @include media-breakpoint-down(xs) {
    .title {
      font-size: px(17);
    }
  }
}
