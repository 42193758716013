// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;

	&.back-top {
		width: 50px;
		height: 50px;
		background-position: 0 0;
	}

	&.picker-date,
	&.picker-place,
	&.picker-time {
		width: 40px;
		height: 40px;
	}

	&.picker-date {
		background-position: -50px 0;
	}

	&.picker-place {
		background-position: -90px 0;
	}

	&.picker-time {
		background-position: -130px 0;
	}

	&.arrow-white {
		width: 20px;
		height: 20px;
		background-position: -170px 0;
	}

	&.cb-payment {
		width: 115px;
		height: 25px;
		background-position: 0 -50px;
	}
	&.car-box,
	&.car-clim,
	&.car-door,
	&.car-gazoil,
	&.car-luggage,
	&.car-people,
	&.car-radio {
		width: 18px;
		height: 15px;
	}

	&.car-gazoil {
		background-position: -145px -50px;
	}

	&.car-clim {
		background-position: -165px -50px;
	}

	&.car-box {
		background-position: -185px -50px;
	}

	&.car-door {
		background-position: -205px -50px;
	}

	&.car-people {
		background-position: -225px -50px;
	}

	&.car-radio {
		background-position: -245px -50px;
	}

	&.car-luggage {
		background-position: -265px -50px;
	}

}
