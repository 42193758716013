// Reset :: ul & ol

figure,
ol,
ul {
  margin: px(0);
  padding: px(0);
}

ul {
  list-style: none;
}
// Viewport :: Fixing mobile device
@-webkit-viewport {
  width: device-width;
  zoom: 1.0;
}
@-moz-viewport {
  width: device-width;
  zoom: 1.0;
}
@-ms-viewport {
  width: device-width;
  zoom: 1.0;
}
@-o-viewport {
  width: device-width;
  zoom: 1.0;
}
@viewport {
  width: device-width;
  zoom: 1.0;
}
// Width full :: Helper

.w-full {
  display: block;
  width: 100%;
}

img.w-full {
  height: auto;
}
@include media-breakpoint-down(lg) {
  .w-full-lg {
    display: block !important;
    width: 100% !important;
  }

  img.w-full-lg {
    height: auto !important;
  }
}
@include media-breakpoint-down(md) {
  .w-full-md {
    display: block !important;
    width: 100% !important;
  }

  img.w-full-md {
    height: auto !important;
  }
}
@include media-breakpoint-down(sm) {
  .w-full-sm {
    display: block !important;
    width: 100% !important;
  }

  img.w-full-sm {
    height: auto !important;
  }
}
@include media-breakpoint-down(xs) {
  .w-full-xs {
    display: block !important;
    width: 100% !important;
  }

  img.w-full-xs {
    height: auto !important;
  }
}
// Flex Align :: Helper
@include media-breakpoint-down(lg) {
  .align-items-start-lg {
    align-items: flex-start !important;
  }

  .align-items-center-lg {
    align-items: center !important;
  }

  .align-items-end-lg {
    align-items: flex-end !important;
  }

  .justify-content-start-lg {
    justify-content: flex-start !important;
  }

  .justify-content-center-lg {
    justify-content: center !important;
  }

  .justify-content-end-lg {
    justify-content: flex-end !important;
  }

  .justify-content-around-lg {
    justify-content: space-around !important;
  }

  .justify-content-between-lg {
    justify-content: space-between !important;
  }
}
@include media-breakpoint-down(md) {
  .align-items-start-md {
    align-items: flex-start !important;
  }

  .align-items-center-md {
    align-items: center !important;
  }

  .align-items-end-md {
    align-items: flex-end !important;
  }

  .justify-content-start-md {
    justify-content: flex-start !important;
  }

  .justify-content-center-md {
    justify-content: center !important;
  }

  .justify-content-end-md {
    justify-content: flex-end !important;
  }

  .justify-content-around-md {
    justify-content: space-around !important;
  }

  .justify-content-between-md {
    justify-content: space-between !important;
  }
}
@include media-breakpoint-down(sm) {
  .align-items-start-sm {
    align-items: flex-start !important;
  }

  .align-items-center-sm {
    align-items: center !important;
  }

  .align-items-end-sm {
    align-items: flex-end !important;
  }

  .justify-content-start-sm {
    justify-content: flex-start !important;
  }

  .justify-content-center-sm {
    justify-content: center !important;
  }

  .justify-content-end-sm {
    justify-content: flex-end !important;
  }

  .justify-content-around-sm {
    justify-content: space-around !important;
  }

  .justify-content-between-sm {
    justify-content: space-between !important;
  }
}
@include media-breakpoint-down(xs) {
  .align-items-start-xs {
    align-items: flex-start !important;
  }

  .align-items-center-xs {
    align-items: center !important;
  }

  .align-items-end-xs {
    align-items: flex-end !important;
  }

  .justify-content-start-xs {
    justify-content: flex-start !important;
  }

  .justify-content-center-xs {
    justify-content: center !important;
  }

  .justify-content-end-xs {
    justify-content: flex-end !important;
  }

  .justify-content-around-xs {
    justify-content: space-around !important;
  }

  .justify-content-between-xs {
    justify-content: space-between !important;
  }
}
// Margin & Padding :: Helper
// Margin :: All

.ma-0 {
  margin: px(0);
}

.ma-5 {
  margin: px(5);
}

.ma-10 {
  margin: px(10);
}

.ma-15 {
  margin: px(15);
}

.ma-20 {
  margin: px(20);
}

.ma-25 {
  margin: px(25);
}

.ma-30 {
  margin: px(30);
}

.ma-35 {
  margin: px(35);
}

.ma-40 {
  margin: px(40);
}

.ma-45 {
  margin: px(45);
}

.ma-50 {
  margin: px(50);
}

.ma-55 {
  margin: px(55);
}

.ma-60 {
  margin: px(60);
}

.ma-65 {
  margin: px(65);
}

.ma-70 {
  margin: px(70);
}

.ma-75 {
  margin: px(75);
}

.ma-80 {
  margin: px(80);
}

.ma-85 {
  margin: px(85);
}

.ma-90 {
  margin: px(90);
}

.ma-95 {
  margin: px(95);
}

.ma-100 {
  margin: px(100);
}
// Margin :: Vertical

.mv-0 {
  margin-top: px(0);
  margin-bottom: px(0);
}

.mv-5 {
  margin-top: px(5) !important;
  margin-bottom: px(5) !important;
}

.mv-10 {
  margin-top: px(10);
  margin-bottom: px(10);
}

.mv-15 {
  margin-top: px(15);
  margin-bottom: px(15);
}

.mv-20 {
  margin-top: px(20);
  margin-bottom: px(20);
}

.mv-25 {
  margin-top: px(25);
  margin-bottom: px(25);
}

.mv-30 {
  margin-top: px(30);
  margin-bottom: px(30);
}

.mv-35 {
  margin-top: px(35);
  margin-bottom: px(35);
}

.mv-40 {
  margin-top: px(40);
  margin-bottom: px(40);
}

.mv-45 {
  margin-top: px(45);
  margin-bottom: px(45);
}

.mv-50 {
  margin-top: px(50);
  margin-bottom: px(50);
}

.mv-55 {
  margin-top: px(55);
  margin-bottom: px(55);
}

.mv-60 {
  margin-top: px(60);
  margin-bottom: px(60);
}

.mv-65 {
  margin-top: px(65);
  margin-bottom: px(65);
}

.mv-70 {
  margin-top: px(70);
  margin-bottom: px(70);
}

.mv-75 {
  margin-top: px(75);
  margin-bottom: px(75);
}

.mv-80 {
  margin-top: px(80);
  margin-bottom: px(80);
}

.mv-85 {
  margin-top: px(85);
  margin-bottom: px(85);
}

.mv-90 {
  margin-top: px(90);
  margin-bottom: px(90);
}

.mv-95 {
  margin-top: px(95);
  margin-bottom: px(95);
}

.mv-100 {
  margin-top: px(100);
  margin-bottom: px(100);
}
// Margin :: Horizontal

.mh-0 {
  margin-left: px(0);
  margin-right: px(0);
}

.mh-5 {
  margin-left: px(5) !important;
  margin-right: px(5) !important;
}

.mh-10 {
  margin-left: px(10);
  margin-right: px(10);
}

.mh-15 {
  margin-left: px(15);
  margin-right: px(15);
}

.mh-20 {
  margin-left: px(20);
  margin-right: px(20);
}

.mh-25 {
  margin-left: px(25);
  margin-right: px(25);
}

.mh-30 {
  margin-left: px(30);
  margin-right: px(30);
}

.mh-35 {
  margin-left: px(35);
  margin-right: px(35);
}

.mh-40 {
  margin-left: px(40);
  margin-right: px(40);
}

.mh-45 {
  margin-left: px(45);
  margin-right: px(45);
}

.mh-50 {
  margin-left: px(50);
  margin-right: px(50);
}

.mh-55 {
  margin-left: px(55);
  margin-right: px(55);
}

.mh-60 {
  margin-left: px(60);
  margin-right: px(60);
}

.mh-65 {
  margin-left: px(65);
  margin-right: px(65);
}

.mh-70 {
  margin-left: px(70);
  margin-right: px(70);
}

.mh-75 {
  margin-left: px(75);
  margin-right: px(75);
}

.mh-80 {
  margin-left: px(80);
  margin-right: px(80);
}

.mh-85 {
  margin-left: px(85);
  margin-right: px(85);
}

.mh-90 {
  margin-left: px(90);
  margin-right: px(90);
}

.mh-95 {
  margin-left: px(95);
  margin-right: px(95);
}

.mh-100 {
  margin-left: px(100);
  margin-right: px(100);
}
// Margin :: Top

.mt-0 {
  margin-top: px(0);
}

.mt-5 {
  margin-top: px(5) !important;
}

.mt-10 {
  margin-top: px(10);
}

.mt-15 {
  margin-top: px(15);
}

.mt-20 {
  margin-top: px(20);
}

.mt-25 {
  margin-top: px(25);
}

.mt-27 {
  margin-top: 27px;
}

.mt-30 {
  margin-top: px(30);
}

.mt-35 {
  margin-top: px(35);
}

.mt-40 {
  margin-top: px(40);
}

.mt-45 {
  margin-top: px(45);
}

.mt-50 {
  margin-top: px(50);
}

.mt-55 {
  margin-top: px(55);
}

.mt-60 {
  margin-top: px(60);
}

.mt-65 {
  margin-top: px(65);
}

.mt-70 {
  margin-top: px(70);
}

.mt-75 {
  margin-top: px(75);
}

.mt-80 {
  margin-top: px(80);
}

.mt-85 {
  margin-top: px(85);
}

.mt-90 {
  margin-top: px(90);
}

.mt-95 {
  margin-top: px(95);
}

.mt-100 {
  margin-top: px(100);
}

.mt-160 {
  margin-top: 160px;
}
// Margin :: Right

.mr-0 {
  margin-right: px(0);
}

.mr-5 {
  margin-right: px(5) !important;
}

.mr-10 {
  margin-right: px(10);
}

.mr-15 {
  margin-right: px(15);
}

.mr-20 {
  margin-right: px(20);
}

.mr-25 {
  margin-right: px(25);
}

.mr-30 {
  margin-right: px(30);
}

.mr-35 {
  margin-right: px(35);
}

.mr-40 {
  margin-right: px(40);
}

.mr-45 {
  margin-right: px(45);
}

.mr-50 {
  margin-right: px(50);
}

.mr-55 {
  margin-right: px(55);
}

.mr-60 {
  margin-right: px(60);
}

.mr-65 {
  margin-right: px(65);
}

.mr-70 {
  margin-right: px(70);
}

.mr-75 {
  margin-right: px(75);
}

.mr-80 {
  margin-right: px(80);
}

.mr-85 {
  margin-right: px(85);
}

.mr-90 {
  margin-right: px(90);
}

.mr-95 {
  margin-right: px(95);
}

.mr-100 {
  margin-right: px(100);
}
// Margin :: Bottom

.mb-0 {
  margin-bottom: px(0);
}

.mb-5 {
  margin-bottom: px(5) !important;
}

.mb-10 {
  margin-bottom: px(10);
}

.mb-15 {
  margin-bottom: px(15);
}

.mb-20 {
  margin-bottom: px(20);
}

.mb-25 {
  margin-bottom: px(25);
}

.mb-30 {
  margin-bottom: px(30);
}

.mb-35 {
  margin-bottom: px(35);
}

.mb-40 {
  margin-bottom: px(40);
}

.mb-45 {
  margin-bottom: px(45);
}

.mb-50 {
  margin-bottom: px(50);
}

.mb-55 {
  margin-bottom: px(55);
}

.mb-60 {
  margin-bottom: px(60);
}

.mb-65 {
  margin-bottom: px(65);
}

.mb-70 {
  margin-bottom: px(70);
}

.mb-75 {
  margin-bottom: px(75);
}

.mb-80 {
  margin-bottom: px(80);
}

.mb-85 {
  margin-bottom: px(85);
}

.mb-90 {
  margin-bottom: px(90);
}

.mb-95 {
  margin-bottom: px(95);
}

.mb-100 {
  margin-bottom: px(100);
}
// Margin :: Left

.ml-0 {
  margin-left: px(0);
}

.ml-5 {
  margin-left: px(5) !important;
}

.ml-10 {
  margin-left: px(10);
}

.ml-15 {
  margin-left: px(15);
}

.ml-20 {
  margin-left: px(20);
}

.ml-25 {
  margin-left: px(25);
}

.ml-30 {
  margin-left: px(30);
}

.ml-35 {
  margin-left: px(35);
}

.ml-40 {
  margin-left: px(40);
}

.ml-45 {
  margin-left: px(45);
}

.ml-50 {
  margin-left: px(50);
}

.ml-55 {
  margin-left: px(55);
}

.ml-60 {
  margin-left: px(60);
}

.ml-65 {
  margin-left: px(65);
}

.ml-70 {
  margin-left: px(70);
}

.ml-75 {
  margin-left: px(75);
}

.ml-80 {
  margin-left: px(80);
}

.ml-85 {
  margin-left: px(85);
}

.ml-90 {
  margin-left: px(90);
}

.ml-95 {
  margin-left: px(95);
}

.ml-100 {
  margin-left: px(100);
}
// Padding :: All

.pa-0 {
  padding: px(0);
}

.pa-5 {
  padding: px(5);
}

.pa-10 {
  padding: px(10);
}

.pa-15 {
  padding: px(15);
}

.pa-20 {
  padding: px(20);
}

.pa-25 {
  padding: px(25);
}

.pa-30 {
  padding: px(30);
}

.pa-35 {
  padding: px(35);
}

.pa-40 {
  padding: px(40);
}

.pa-45 {
  padding: px(45);
}

.pa-50 {
  padding: px(50);
}

.pa-55 {
  padding: px(55);
}

.pa-60 {
  padding: px(60);
}

.pa-65 {
  padding: px(65);
}

.pa-70 {
  padding: px(70);
}

.pa-75 {
  padding: px(75);
}

.pa-80 {
  padding: px(80);
}

.pa-85 {
  padding: px(85);
}

.pa-90 {
  padding: px(90);
}

.pa-95 {
  padding: px(95);
}

.pa-100 {
  padding: px(100);
}
// Padding :: Vertical

.pv-0 {
  padding-top: px(0);
  padding-bottom: px(0);
}

.pv-5 {
  padding-top: px(5);
  padding-bottom: px(5);
}

.pv-10 {
  padding-top: px(10);
  padding-bottom: px(10);
}

.pv-15 {
  padding-top: px(15);
  padding-bottom: px(15);
}

.pv-20 {
  padding-top: px(20);
  padding-bottom: px(20);
}

.pv-25 {
  padding-top: px(25);
  padding-bottom: px(25);
}

.pv-30 {
  padding-top: px(30);
  padding-bottom: px(30);
}

.pv-35 {
  padding-top: px(35);
  padding-bottom: px(35);
}

.pv-40 {
  padding-top: px(40);
  padding-bottom: px(40);
}

.pv-45 {
  padding-top: px(45);
  padding-bottom: px(45);
}

.pv-50 {
  padding-top: px(50);
  padding-bottom: px(50);
}

.pv-55 {
  padding-top: px(55);
  padding-bottom: px(55);
}

.pv-60 {
  padding-top: px(60);
  padding-bottom: px(60);
}

.pv-65 {
  padding-top: px(65);
  padding-bottom: px(65);
}

.pv-70 {
  padding-top: px(70);
  padding-bottom: px(70);
}

.pv-75 {
  padding-top: px(75);
  padding-bottom: px(75);
}

.pv-80 {
  padding-top: px(80);
  padding-bottom: px(80);
}

.pv-85 {
  padding-top: px(85);
  padding-bottom: px(85);
}

.pv-90 {
  padding-top: px(90);
  padding-bottom: px(90);
}

.pv-95 {
  padding-top: px(95);
  padding-bottom: px(95);
}

.pv-100 {
  padding-top: px(100);
  padding-bottom: px(100);
}
// Padding :: Horizontal

.ph-0 {
  padding-left: px(0);
  padding-right: px(0);
}

.ph-5 {
  padding-left: px(5) !important;
  padding-right: px(5) !important;
}

.ph-10 {
  padding-left: px(10);
  padding-right: px(10);
}

.ph-15 {
  padding-left: px(15);
  padding-right: px(15);
}

.ph-20 {
  padding-left: px(20);
  padding-right: px(20);
}

.ph-25 {
  padding-left: px(25);
  padding-right: px(25);
}

.ph-30 {
  padding-left: px(30);
  padding-right: px(30);
}

.ph-35 {
  padding-left: px(35);
  padding-right: px(35);
}

.ph-40 {
  padding-left: px(40);
  padding-right: px(40);
}

.ph-45 {
  padding-left: px(45);
  padding-right: px(45);
}

.ph-50 {
  padding-left: px(50);
  padding-right: px(50);
}

.ph-55 {
  padding-left: px(55);
  padding-right: px(55);
}

.ph-60 {
  padding-left: px(60);
  padding-right: px(60);
}

.ph-65 {
  padding-left: px(65);
  padding-right: px(65);
}

.ph-70 {
  padding-left: px(70);
  padding-right: px(70);
}

.ph-75 {
  padding-left: px(75);
  padding-right: px(75);
}

.ph-80 {
  padding-left: px(80);
  padding-right: px(80);
}

.ph-85 {
  padding-left: px(85);
  padding-right: px(85);
}

.ph-90 {
  padding-left: px(90);
  padding-right: px(90);
}

.ph-95 {
  padding-left: px(95);
  padding-right: px(95);
}

.ph-100 {
  padding-left: px(100);
  padding-right: px(100);
}
// Padding :: Top

.pt-0 {
  padding-top: px(0);
}

.pt-5 {
  padding-top: px(5) !important;
}

.pt-10 {
  padding-top: px(10);
}

.pt-15 {
  padding-top: px(15);
}

.pt-20 {
  padding-top: px(20);
}

.pt-25 {
  padding-top: px(25);
}

.pt-30 {
  padding-top: px(30);
}

.pt-35 {
  padding-top: px(35);
}

.pt-40 {
  padding-top: px(40);
}

.pt-45 {
  padding-top: px(45);
}

.pt-50 {
  padding-top: px(50);
}

.pt-55 {
  padding-top: px(55);
}

.pt-60 {
  padding-top: px(60);
}

.pt-65 {
  padding-top: px(65);
}

.pt-70 {
  padding-top: px(70);
}

.pt-75 {
  padding-top: px(75);
}

.pt-80 {
  padding-top: px(80);
}

.pt-85 {
  padding-top: px(85);
}

.pt-90 {
  padding-top: px(90);
}

.pt-95 {
  padding-top: px(95);
}

.pt-100 {
  padding-top: px(100);
}
// Padding :: Right

.pr-0 {
  padding-right: px(0);
}

.pr-5 {
  padding-right: px(5) !important;
}

.pr-10 {
  padding-right: px(10);
}

.pr-15 {
  padding-right: px(15);
}

.pr-20 {
  padding-right: px(20);
}

.pr-25 {
  padding-right: px(25);
}

.pr-30 {
  padding-right: px(30);
}

.pr-35 {
  padding-right: px(35);
}

.pr-40 {
  padding-right: px(40);
}

.pr-45 {
  padding-right: px(45);
}

.pr-50 {
  padding-right: px(50);
}

.pr-55 {
  padding-right: px(55);
}

.pr-60 {
  padding-right: px(60);
}

.pr-65 {
  padding-right: px(65);
}

.pr-70 {
  padding-right: px(70);
}

.pr-75 {
  padding-right: px(75);
}

.pr-80 {
  padding-right: px(80);
}

.pr-85 {
  padding-right: px(85);
}

.pr-90 {
  padding-right: px(90);
}

.pr-95 {
  padding-right: px(95);
}

.pr-100 {
  padding-right: px(100);
}
// Padding :: Bottom

.pb-0 {
  padding-bottom: px(0);
}

.pb-5 {
  padding-bottom: px(5) !important;
}

.pb-10 {
  padding-bottom: px(10);
}

.pb-15 {
  padding-bottom: px(15);
}

.pb-20 {
  padding-bottom: px(20);
}

.pb-25 {
  padding-bottom: px(25);
}

.pb-30 {
  padding-bottom: px(30);
}

.pb-35 {
  padding-bottom: px(35);
}

.pb-40 {
  padding-bottom: px(40);
}

.pb-45 {
  padding-bottom: px(45);
}

.pb-50 {
  padding-bottom: px(50);
}

.pb-55 {
  padding-bottom: px(55);
}

.pb-60 {
  padding-bottom: px(60);
}

.pb-65 {
  padding-bottom: px(65);
}

.pb-70 {
  padding-bottom: px(70);
}

.pb-75 {
  padding-bottom: px(75);
}

.pb-80 {
  padding-bottom: px(80);
}

.pb-85 {
  padding-bottom: px(85);
}

.pb-90 {
  padding-bottom: px(90);
}

.pb-95 {
  padding-bottom: px(95);
}

.pb-100 {
  padding-bottom: px(100);
}
// Padding :: Left

.pl-0 {
  padding-left: px(0);
}

.pl-5 {
  padding-left: px(5) !important;
}

.pl-10 {
  padding-left: px(10);
}

.pl-15 {
  padding-left: px(15);
}

.pl-20 {
  padding-left: px(20);
}

.pl-25 {
  padding-left: px(25);
}

.pl-30 {
  padding-left: px(30);
}

.pl-35 {
  padding-left: px(35);
}

.pl-40 {
  padding-left: px(40);
}

.pl-45 {
  padding-left: px(45);
}

.pl-50 {
  padding-left: px(50);
}

.pl-55 {
  padding-left: px(55);
}

.pl-60 {
  padding-left: px(60);
}

.pl-65 {
  padding-left: px(65);
}

.pl-70 {
  padding-left: px(70);
}

.pl-75 {
  padding-left: px(75);
}

.pl-80 {
  padding-left: px(80);
}

.pl-85 {
  padding-left: px(85);
}

.pl-90 {
  padding-left: px(90);
}

.pl-95 {
  padding-left: px(95);
}

.pl-100 {
  padding-left: px(100);
}
@include media-breakpoint-down(lg) {
  // Margin :: All
  .ma-0-lg {
    margin: px(0)!important !important;
  }

  .ma-5-lg {
    margin: px(5) !important;
  }

  .ma-10-lg {
    margin: px(10) !important;
  }

  .ma-15-lg {
    margin: px(15) !important;
  }

  .ma-20-lg {
    margin: px(20) !important;
  }

  .ma-25-lg {
    margin: px(25) !important;
  }

  .ma-30-lg {
    margin: px(30) !important;
  }

  .ma-35-lg {
    margin: px(35) !important;
  }

  .ma-40-lg {
    margin: px(40) !important;
  }

  .ma-45-lg {
    margin: px(45) !important;
  }

  .ma-50-lg {
    margin: px(50) !important;
  }

  .ma-55-lg {
    margin: px(55) !important;
  }

  .ma-60-lg {
    margin: px(60) !important;
  }

  .ma-65-lg {
    margin: px(65) !important;
  }

  .ma-70-lg {
    margin: px(70) !important;
  }

  .ma-75-lg {
    margin: px(75) !important;
  }

  .ma-80-lg {
    margin: px(80) !important;
  }

  .ma-85-lg {
    margin: px(85) !important;
  }

  .ma-90-lg {
    margin: px(90) !important;
  }

  .ma-95-lg {
    margin: px(95) !important;
  }

  .ma-100-lg {
    margin: px(100) !important;
  }
  // Margin :: Vertical
  .mv-0-lg {
    margin-top: px(0) !important;
    margin-bottom: px(0) !important;
  }

  .mv-5-lg {
    margin-top: px(5) !important;
    margin-bottom: px(5) !important;
  }

  .mv-10-lg {
    margin-top: px(10) !important;
    margin-bottom: px(10) !important;
  }

  .mv-15-lg {
    margin-top: px(15) !important;
    margin-bottom: px(15) !important;
  }

  .mv-20-lg {
    margin-top: px(20) !important;
    margin-bottom: px(20) !important;
  }

  .mv-25-lg {
    margin-top: px(25) !important;
    margin-bottom: px(25) !important;
  }

  .mv-30-lg {
    margin-top: px(30) !important;
    margin-bottom: px(30) !important;
  }

  .mv-35-lg {
    margin-top: px(35) !important;
    margin-bottom: px(35) !important;
  }

  .mv-40-lg {
    margin-top: px(40) !important;
    margin-bottom: px(40) !important;
  }

  .mv-45-lg {
    margin-top: px(45) !important;
    margin-bottom: px(45) !important;
  }

  .mv-50-lg {
    margin-top: px(50) !important;
    margin-bottom: px(50) !important;
  }

  .mv-55-lg {
    margin-top: px(55) !important;
    margin-bottom: px(55) !important;
  }

  .mv-60-lg {
    margin-top: px(60) !important;
    margin-bottom: px(60) !important;
  }

  .mv-65-lg {
    margin-top: px(65) !important;
    margin-bottom: px(65) !important;
  }

  .mv-70-lg {
    margin-top: px(70) !important;
    margin-bottom: px(70) !important;
  }

  .mv-75-lg {
    margin-top: px(75) !important;
    margin-bottom: px(75) !important;
  }

  .mv-80-lg {
    margin-top: px(80) !important;
    margin-bottom: px(80) !important;
  }

  .mv-85-lg {
    margin-top: px(85) !important;
    margin-bottom: px(85) !important;
  }

  .mv-90-lg {
    margin-top: px(90) !important;
    margin-bottom: px(90) !important;
  }

  .mv-95-lg {
    margin-top: px(95) !important;
    margin-bottom: px(95) !important;
  }

  .mv-100-lg {
    margin-top: px(100) !important;
    margin-bottom: px(100) !important;
  }
  // Margin :: Horizontal
  .mh-0-lg {
    margin-left: px(0) !important;
    margin-right: px(0) !important;
  }

  .mh-5-lg {
    margin-left: px(5) !important;
    margin-right: px(5) !important;
  }

  .mh-10-lg {
    margin-left: px(10) !important;
    margin-right: px(10) !important;
  }

  .mh-15-lg {
    margin-left: px(15) !important;
    margin-right: px(15) !important;
  }

  .mh-20-lg {
    margin-left: px(20) !important;
    margin-right: px(20) !important;
  }

  .mh-25-lg {
    margin-left: px(25) !important;
    margin-right: px(25) !important;
  }

  .mh-30-lg {
    margin-left: px(30) !important;
    margin-right: px(30) !important;
  }

  .mh-35-lg {
    margin-left: px(35) !important;
    margin-right: px(35) !important;
  }

  .mh-40-lg {
    margin-left: px(40) !important;
    margin-right: px(40) !important;
  }

  .mh-45-lg {
    margin-left: px(45) !important;
    margin-right: px(45) !important;
  }

  .mh-50-lg {
    margin-left: px(50) !important;
    margin-right: px(50) !important;
  }

  .mh-55-lg {
    margin-left: px(55) !important;
    margin-right: px(55) !important;
  }

  .mh-60-lg {
    margin-left: px(60) !important;
    margin-right: px(60) !important;
  }

  .mh-65-lg {
    margin-left: px(65) !important;
    margin-right: px(65) !important;
  }

  .mh-70-lg {
    margin-left: px(70) !important;
    margin-right: px(70) !important;
  }

  .mh-75-lg {
    margin-left: px(75) !important;
    margin-right: px(75) !important;
  }

  .mh-80-lg {
    margin-left: px(80) !important;
    margin-right: px(80) !important;
  }

  .mh-85-lg {
    margin-left: px(85) !important;
    margin-right: px(85) !important;
  }

  .mh-90-lg {
    margin-left: px(90) !important;
    margin-right: px(90) !important;
  }

  .mh-95-lg {
    margin-left: px(95) !important;
    margin-right: px(95) !important;
  }

  .mh-100-lg {
    margin-left: px(100) !important;
    margin-right: px(100) !important;
  }
  // Margin :: Top
  .mt-0-lg {
    margin-top: px(0) !important;
  }

  .mt-5-lg {
    margin-top: px(5) !important;
  }

  .mt-10-lg {
    margin-top: px(10) !important;
  }

  .mt-15-lg {
    margin-top: px(15) !important;
  }

  .mt-20-lg {
    margin-top: px(20) !important;
  }

  .mt-25-lg {
    margin-top: px(25) !important;
  }

  .mt-30-lg {
    margin-top: px(30) !important;
  }

  .mt-35-lg {
    margin-top: px(35) !important;
  }

  .mt-40-lg {
    margin-top: px(40) !important;
  }

  .mt-45-lg {
    margin-top: px(45) !important;
  }

  .mt-50-lg {
    margin-top: px(50) !important;
  }

  .mt-55-lg {
    margin-top: px(55) !important;
  }

  .mt-60-lg {
    margin-top: px(60) !important;
  }

  .mt-65-lg {
    margin-top: px(65) !important;
  }

  .mt-70-lg {
    margin-top: px(70) !important;
  }

  .mt-75-lg {
    margin-top: px(75) !important;
  }

  .mt-80-lg {
    margin-top: px(80) !important;
  }

  .mt-85-lg {
    margin-top: px(85) !important;
  }

  .mt-90-lg {
    margin-top: px(90) !important;
  }

  .mt-95-lg {
    margin-top: px(95) !important;
  }

  .mt-100-lg {
    margin-top: px(100) !important;
  }
  // Margin :: Right
  .mr-0-lg {
    margin-right: px(0) !important;
  }

  .mr-5-lg {
    margin-right: px(5) !important;
  }

  .mr-10-lg {
    margin-right: px(10) !important;
  }

  .mr-15-lg {
    margin-right: px(15) !important;
  }

  .mr-20-lg {
    margin-right: px(20) !important;
  }

  .mr-25-lg {
    margin-right: px(25) !important;
  }

  .mr-30-lg {
    margin-right: px(30) !important;
  }

  .mr-35-lg {
    margin-right: px(35) !important;
  }

  .mr-40-lg {
    margin-right: px(40) !important;
  }

  .mr-45-lg {
    margin-right: px(45) !important;
  }

  .mr-50-lg {
    margin-right: px(50) !important;
  }

  .mr-55-lg {
    margin-right: px(55) !important;
  }

  .mr-60-lg {
    margin-right: px(60) !important;
  }

  .mr-65-lg {
    margin-right: px(65) !important;
  }

  .mr-70-lg {
    margin-right: px(70) !important;
  }

  .mr-75-lg {
    margin-right: px(75) !important;
  }

  .mr-80-lg {
    margin-right: px(80) !important;
  }

  .mr-85-lg {
    margin-right: px(85) !important;
  }

  .mr-90-lg {
    margin-right: px(90) !important;
  }

  .mr-95-lg {
    margin-right: px(95) !important;
  }

  .mr-100-lg {
    margin-right: px(100) !important;
  }
  // Margin :: Bottom
  .mb-0-lg {
    margin-bottom: px(0) !important;
  }

  .mb-5-lg {
    margin-bottom: px(5) !important;
  }

  .mb-10-lg {
    margin-bottom: px(10) !important;
  }

  .mb-15-lg {
    margin-bottom: px(15) !important;
  }

  .mb-20-lg {
    margin-bottom: px(20) !important;
  }

  .mb-25-lg {
    margin-bottom: px(25) !important;
  }

  .mb-30-lg {
    margin-bottom: px(30) !important;
  }

  .mb-35-lg {
    margin-bottom: px(35) !important;
  }

  .mb-40-lg {
    margin-bottom: px(40) !important;
  }

  .mb-45-lg {
    margin-bottom: px(45) !important;
  }

  .mb-50-lg {
    margin-bottom: px(50) !important;
  }

  .mb-55-lg {
    margin-bottom: px(55) !important;
  }

  .mb-60-lg {
    margin-bottom: px(60) !important;
  }

  .mb-65-lg {
    margin-bottom: px(65) !important;
  }

  .mb-70-lg {
    margin-bottom: px(70) !important;
  }

  .mb-75-lg {
    margin-bottom: px(75) !important;
  }

  .mb-80-lg {
    margin-bottom: px(80) !important;
  }

  .mb-85-lg {
    margin-bottom: px(85) !important;
  }

  .mb-90-lg {
    margin-bottom: px(90) !important;
  }

  .mb-95-lg {
    margin-bottom: px(95) !important;
  }

  .mb-100-lg {
    margin-bottom: px(100) !important;
  }
  // Margin :: Left
  .ml-0-lg {
    margin-left: px(0) !important;
  }

  .ml-5-lg {
    margin-left: px(5) !important;
  }

  .ml-10-lg {
    margin-left: px(10) !important;
  }

  .ml-15-lg {
    margin-left: px(15) !important;
  }

  .ml-20-lg {
    margin-left: px(20) !important;
  }

  .ml-25-lg {
    margin-left: px(25) !important;
  }

  .ml-30-lg {
    margin-left: px(30) !important;
  }

  .ml-35-lg {
    margin-left: px(35) !important;
  }

  .ml-40-lg {
    margin-left: px(40) !important;
  }

  .ml-45-lg {
    margin-left: px(45) !important;
  }

  .ml-50-lg {
    margin-left: px(50) !important;
  }

  .ml-55-lg {
    margin-left: px(55) !important;
  }

  .ml-60-lg {
    margin-left: px(60) !important;
  }

  .ml-65-lg {
    margin-left: px(65) !important;
  }

  .ml-70-lg {
    margin-left: px(70) !important;
  }

  .ml-75-lg {
    margin-left: px(75) !important;
  }

  .ml-80-lg {
    margin-left: px(80) !important;
  }

  .ml-85-lg {
    margin-left: px(85) !important;
  }

  .ml-90-lg {
    margin-left: px(90) !important;
  }

  .ml-95-lg {
    margin-left: px(95) !important;
  }

  .ml-100-lg {
    margin-left: px(100) !important;
  }
  // Padding :: All
  .pa-0-lg {
    padding: px(0) !important;
  }

  .pa-5-lg {
    padding: px(5) !important;
  }

  .pa-10-lg {
    padding: px(10) !important;
  }

  .pa-15-lg {
    padding: px(15) !important;
  }

  .pa-20-lg {
    padding: px(20) !important;
  }

  .pa-25-lg {
    padding: px(25) !important;
  }

  .pa-30-lg {
    padding: px(30) !important;
  }

  .pa-35-lg {
    padding: px(35) !important;
  }

  .pa-40-lg {
    padding: px(40) !important;
  }

  .pa-45-lg {
    padding: px(45) !important;
  }

  .pa-50-lg {
    padding: px(50) !important;
  }

  .pa-55-lg {
    padding: px(55) !important;
  }

  .pa-60-lg {
    padding: px(60) !important;
  }

  .pa-65-lg {
    padding: px(65) !important;
  }

  .pa-70-lg {
    padding: px(70) !important;
  }

  .pa-75-lg {
    padding: px(75) !important;
  }

  .pa-80-lg {
    padding: px(80) !important;
  }

  .pa-85-lg {
    padding: px(85) !important;
  }

  .pa-90-lg {
    padding: px(90) !important;
  }

  .pa-95-lg {
    padding: px(95) !important;
  }

  .pa-100-lg {
    padding: px(100) !important;
  }
  // Padding :: Vertical
  .pv-0-lg {
    padding-top: px(0) !important;
    padding-bottom: px(0) !important;
  }

  .pv-5-lg {
    padding-top: px(5) !important;
    padding-bottom: px(5) !important;
  }

  .pv-10-lg {
    padding-top: px(10) !important;
    padding-bottom: px(10) !important;
  }

  .pv-15-lg {
    padding-top: px(15) !important;
    padding-bottom: px(15) !important;
  }

  .pv-20-lg {
    padding-top: px(20) !important;
    padding-bottom: px(20) !important;
  }

  .pv-25-lg {
    padding-top: px(25) !important;
    padding-bottom: px(25) !important;
  }

  .pv-30-lg {
    padding-top: px(30) !important;
    padding-bottom: px(30) !important;
  }

  .pv-35-lg {
    padding-top: px(35) !important;
    padding-bottom: px(35) !important;
  }

  .pv-40-lg {
    padding-top: px(40) !important;
    padding-bottom: px(40) !important;
  }

  .pv-45-lg {
    padding-top: px(45) !important;
    padding-bottom: px(45) !important;
  }

  .pv-50-lg {
    padding-top: px(50) !important;
    padding-bottom: px(50) !important;
  }

  .pv-55-lg {
    padding-top: px(55) !important;
    padding-bottom: px(55) !important;
  }

  .pv-60-lg {
    padding-top: px(60) !important;
    padding-bottom: px(60) !important;
  }

  .pv-65-lg {
    padding-top: px(65) !important;
    padding-bottom: px(65) !important;
  }

  .pv-70-lg {
    padding-top: px(70) !important;
    padding-bottom: px(70) !important;
  }

  .pv-75-lg {
    padding-top: px(75) !important;
    padding-bottom: px(75) !important;
  }

  .pv-80-lg {
    padding-top: px(80) !important;
    padding-bottom: px(80) !important;
  }

  .pv-85-lg {
    padding-top: px(85) !important;
    padding-bottom: px(85) !important;
  }

  .pv-90-lg {
    padding-top: px(90) !important;
    padding-bottom: px(90) !important;
  }

  .pv-95-lg {
    padding-top: px(95) !important;
    padding-bottom: px(95) !important;
  }

  .pv-100-lg {
    padding-top: px(100) !important;
    padding-bottom: px(100) !important;
  }
  // Padding :: Horizontal
  .ph-0-lg {
    padding-left: px(0) !important;
    padding-right: px(0) !important;
  }

  .ph-5-lg {
    padding-left: px(5) !important;
    padding-right: px(5) !important;
  }

  .ph-10-lg {
    padding-left: px(10) !important;
    padding-right: px(10) !important;
  }

  .ph-15-lg {
    padding-left: px(15) !important;
    padding-right: px(15) !important;
  }

  .ph-20-lg {
    padding-left: px(20) !important;
    padding-right: px(20) !important;
  }

  .ph-25-lg {
    padding-left: px(25) !important;
    padding-right: px(25) !important;
  }

  .ph-30-lg {
    padding-left: px(30) !important;
    padding-right: px(30) !important;
  }

  .ph-35-lg {
    padding-left: px(35) !important;
    padding-right: px(35) !important;
  }

  .ph-40-lg {
    padding-left: px(40) !important;
    padding-right: px(40) !important;
  }

  .ph-45-lg {
    padding-left: px(45) !important;
    padding-right: px(45) !important;
  }

  .ph-50-lg {
    padding-left: px(50) !important;
    padding-right: px(50) !important;
  }

  .ph-55-lg {
    padding-left: px(55) !important;
    padding-right: px(55) !important;
  }

  .ph-60-lg {
    padding-left: px(60) !important;
    padding-right: px(60) !important;
  }

  .ph-65-lg {
    padding-left: px(65) !important;
    padding-right: px(65) !important;
  }

  .ph-70-lg {
    padding-left: px(70) !important;
    padding-right: px(70) !important;
  }

  .ph-75-lg {
    padding-left: px(75) !important;
    padding-right: px(75) !important;
  }

  .ph-80-lg {
    padding-left: px(80) !important;
    padding-right: px(80) !important;
  }

  .ph-85-lg {
    padding-left: px(85) !important;
    padding-right: px(85) !important;
  }

  .ph-90-lg {
    padding-left: px(90) !important;
    padding-right: px(90) !important;
  }

  .ph-95-lg {
    padding-left: px(95) !important;
    padding-right: px(95) !important;
  }

  .ph-100-lg {
    padding-left: px(100) !important;
    padding-right: px(100) !important;
  }
  // Padding :: Top
  .pt-0-lg {
    padding-top: px(0) !important;
  }

  .pt-5-lg {
    padding-top: px(5) !important;
  }

  .pt-10-lg {
    padding-top: px(10) !important;
  }

  .pt-15-lg {
    padding-top: px(15) !important;
  }

  .pt-20-lg {
    padding-top: px(20) !important;
  }

  .pt-25-lg {
    padding-top: px(25) !important;
  }

  .pt-30-lg {
    padding-top: px(30) !important;
  }

  .pt-35-lg {
    padding-top: px(35) !important;
  }

  .pt-40-lg {
    padding-top: px(40) !important;
  }

  .pt-45-lg {
    padding-top: px(45) !important;
  }

  .pt-50-lg {
    padding-top: px(50) !important;
  }

  .pt-55-lg {
    padding-top: px(55) !important;
  }

  .pt-60-lg {
    padding-top: px(60) !important;
  }

  .pt-65-lg {
    padding-top: px(65) !important;
  }

  .pt-70-lg {
    padding-top: px(70) !important;
  }

  .pt-75-lg {
    padding-top: px(75) !important;
  }

  .pt-80-lg {
    padding-top: px(80) !important;
  }

  .pt-85-lg {
    padding-top: px(85) !important;
  }

  .pt-90-lg {
    padding-top: px(90) !important;
  }

  .pt-95-lg {
    padding-top: px(95) !important;
  }

  .pt-100-lg {
    padding-top: px(100) !important;
  }
  // Padding :: Right
  .pr-0-lg {
    padding-right: px(0) !important;
  }

  .pr-5-lg {
    padding-right: px(5) !important;
  }

  .pr-10-lg {
    padding-right: px(10) !important;
  }

  .pr-15-lg {
    padding-right: px(15) !important;
  }

  .pr-20-lg {
    padding-right: px(20) !important;
  }

  .pr-25-lg {
    padding-right: px(25) !important;
  }

  .pr-30-lg {
    padding-right: px(30) !important;
  }

  .pr-35-lg {
    padding-right: px(35) !important;
  }

  .pr-40-lg {
    padding-right: px(40) !important;
  }

  .pr-45-lg {
    padding-right: px(45) !important;
  }

  .pr-50-lg {
    padding-right: px(50) !important;
  }

  .pr-55-lg {
    padding-right: px(55) !important;
  }

  .pr-60-lg {
    padding-right: px(60) !important;
  }

  .pr-65-lg {
    padding-right: px(65) !important;
  }

  .pr-70-lg {
    padding-right: px(70) !important;
  }

  .pr-75-lg {
    padding-right: px(75) !important;
  }

  .pr-80-lg {
    padding-right: px(80) !important;
  }

  .pr-85-lg {
    padding-right: px(85) !important;
  }

  .pr-90-lg {
    padding-right: px(90) !important;
  }

  .pr-95-lg {
    padding-right: px(95) !important;
  }

  .pr-100-lg {
    padding-right: px(100) !important;
  }
  // Padding :: Bottom
  .pb-0-lg {
    padding-bottom: px(0) !important;
  }

  .pb-5-lg {
    padding-bottom: px(5) !important;
  }

  .pb-10-lg {
    padding-bottom: px(10) !important;
  }

  .pb-15-lg {
    padding-bottom: px(15) !important;
  }

  .pb-20-lg {
    padding-bottom: px(20) !important;
  }

  .pb-25-lg {
    padding-bottom: px(25) !important;
  }

  .pb-30-lg {
    padding-bottom: px(30) !important;
  }

  .pb-35-lg {
    padding-bottom: px(35) !important;
  }

  .pb-40-lg {
    padding-bottom: px(40) !important;
  }

  .pb-45-lg {
    padding-bottom: px(45) !important;
  }

  .pb-50-lg {
    padding-bottom: px(50) !important;
  }

  .pb-55-lg {
    padding-bottom: px(55) !important;
  }

  .pb-60-lg {
    padding-bottom: px(60) !important;
  }

  .pb-65-lg {
    padding-bottom: px(65) !important;
  }

  .pb-70-lg {
    padding-bottom: px(70) !important;
  }

  .pb-75-lg {
    padding-bottom: px(75) !important;
  }

  .pb-80-lg {
    padding-bottom: px(80) !important;
  }

  .pb-85-lg {
    padding-bottom: px(85) !important;
  }

  .pb-90-lg {
    padding-bottom: px(90) !important;
  }

  .pb-95-lg {
    padding-bottom: px(95) !important;
  }

  .pb-100-lg {
    padding-bottom: px(100) !important;
  }
  // Padding :: Left
  .pl-0-lg {
    padding-left: px(0) !important;
  }

  .pl-5-lg {
    padding-left: px(5) !important;
  }

  .pl-10-lg {
    padding-left: px(10) !important;
  }

  .pl-15-lg {
    padding-left: px(15) !important;
  }

  .pl-20-lg {
    padding-left: px(20) !important;
  }

  .pl-25-lg {
    padding-left: px(25) !important;
  }

  .pl-30-lg {
    padding-left: px(30) !important;
  }

  .pl-35-lg {
    padding-left: px(35) !important;
  }

  .pl-40-lg {
    padding-left: px(40) !important;
  }

  .pl-45-lg {
    padding-left: px(45) !important;
  }

  .pl-50-lg {
    padding-left: px(50) !important;
  }

  .pl-55-lg {
    padding-left: px(55) !important;
  }

  .pl-60-lg {
    padding-left: px(60) !important;
  }

  .pl-65-lg {
    padding-left: px(65) !important;
  }

  .pl-70-lg {
    padding-left: px(70) !important;
  }

  .pl-75-lg {
    padding-left: px(75) !important;
  }

  .pl-80-lg {
    padding-left: px(80) !important;
  }

  .pl-85-lg {
    padding-left: px(85) !important;
  }

  .pl-90-lg {
    padding-left: px(90) !important;
  }

  .pl-95-lg {
    padding-left: px(95) !important;
  }

  .pl-100-lg {
    padding-left: px(100) !important;
  }
}
@include media-breakpoint-down(md) {
  // Margin :: All
  .ma-0-md {
    margin: px(0)!important !important;
  }

  .ma-5-md {
    margin: px(5) !important;
  }

  .ma-10-md {
    margin: px(10) !important;
  }

  .ma-15-md {
    margin: px(15) !important;
  }

  .ma-20-md {
    margin: px(20) !important;
  }

  .ma-25-md {
    margin: px(25) !important;
  }

  .ma-30-md {
    margin: px(30) !important;
  }

  .ma-35-md {
    margin: px(35) !important;
  }

  .ma-40-md {
    margin: px(40) !important;
  }

  .ma-45-md {
    margin: px(45) !important;
  }

  .ma-50-md {
    margin: px(50) !important;
  }

  .ma-55-md {
    margin: px(55) !important;
  }

  .ma-60-md {
    margin: px(60) !important;
  }

  .ma-65-md {
    margin: px(65) !important;
  }

  .ma-70-md {
    margin: px(70) !important;
  }

  .ma-75-md {
    margin: px(75) !important;
  }

  .ma-80-md {
    margin: px(80) !important;
  }

  .ma-85-md {
    margin: px(85) !important;
  }

  .ma-90-md {
    margin: px(90) !important;
  }

  .ma-95-md {
    margin: px(95) !important;
  }

  .ma-100-md {
    margin: px(100) !important;
  }
  // Margin :: Vertical
  .mv-0-md {
    margin-top: px(0) !important;
    margin-bottom: px(0) !important;
  }

  .mv-5-md {
    margin-top: px(5) !important;
    margin-bottom: px(5) !important;
  }

  .mv-10-md {
    margin-top: px(10) !important;
    margin-bottom: px(10) !important;
  }

  .mv-15-md {
    margin-top: px(15) !important;
    margin-bottom: px(15) !important;
  }

  .mv-20-md {
    margin-top: px(20) !important;
    margin-bottom: px(20) !important;
  }

  .mv-25-md {
    margin-top: px(25) !important;
    margin-bottom: px(25) !important;
  }

  .mv-30-md {
    margin-top: px(30) !important;
    margin-bottom: px(30) !important;
  }

  .mv-35-md {
    margin-top: px(35) !important;
    margin-bottom: px(35) !important;
  }

  .mv-40-md {
    margin-top: px(40) !important;
    margin-bottom: px(40) !important;
  }

  .mv-45-md {
    margin-top: px(45) !important;
    margin-bottom: px(45) !important;
  }

  .mv-50-md {
    margin-top: px(50) !important;
    margin-bottom: px(50) !important;
  }

  .mv-55-md {
    margin-top: px(55) !important;
    margin-bottom: px(55) !important;
  }

  .mv-60-md {
    margin-top: px(60) !important;
    margin-bottom: px(60) !important;
  }

  .mv-65-md {
    margin-top: px(65) !important;
    margin-bottom: px(65) !important;
  }

  .mv-70-md {
    margin-top: px(70) !important;
    margin-bottom: px(70) !important;
  }

  .mv-75-md {
    margin-top: px(75) !important;
    margin-bottom: px(75) !important;
  }

  .mv-80-md {
    margin-top: px(80) !important;
    margin-bottom: px(80) !important;
  }

  .mv-85-md {
    margin-top: px(85) !important;
    margin-bottom: px(85) !important;
  }

  .mv-90-md {
    margin-top: px(90) !important;
    margin-bottom: px(90) !important;
  }

  .mv-95-md {
    margin-top: px(95) !important;
    margin-bottom: px(95) !important;
  }

  .mv-100-md {
    margin-top: px(100) !important;
    margin-bottom: px(100) !important;
  }
  // Margin :: Horizontal
  .mh-0-md {
    margin-left: px(0) !important;
    margin-right: px(0) !important;
  }

  .mh-5-md {
    margin-left: px(5) !important;
    margin-right: px(5) !important;
  }

  .mh-10-md {
    margin-left: px(10) !important;
    margin-right: px(10) !important;
  }

  .mh-15-md {
    margin-left: px(15) !important;
    margin-right: px(15) !important;
  }

  .mh-20-md {
    margin-left: px(20) !important;
    margin-right: px(20) !important;
  }

  .mh-25-md {
    margin-left: px(25) !important;
    margin-right: px(25) !important;
  }

  .mh-30-md {
    margin-left: px(30) !important;
    margin-right: px(30) !important;
  }

  .mh-35-md {
    margin-left: px(35) !important;
    margin-right: px(35) !important;
  }

  .mh-40-md {
    margin-left: px(40) !important;
    margin-right: px(40) !important;
  }

  .mh-45-md {
    margin-left: px(45) !important;
    margin-right: px(45) !important;
  }

  .mh-50-md {
    margin-left: px(50) !important;
    margin-right: px(50) !important;
  }

  .mh-55-md {
    margin-left: px(55) !important;
    margin-right: px(55) !important;
  }

  .mh-60-md {
    margin-left: px(60) !important;
    margin-right: px(60) !important;
  }

  .mh-65-md {
    margin-left: px(65) !important;
    margin-right: px(65) !important;
  }

  .mh-70-md {
    margin-left: px(70) !important;
    margin-right: px(70) !important;
  }

  .mh-75-md {
    margin-left: px(75) !important;
    margin-right: px(75) !important;
  }

  .mh-80-md {
    margin-left: px(80) !important;
    margin-right: px(80) !important;
  }

  .mh-85-md {
    margin-left: px(85) !important;
    margin-right: px(85) !important;
  }

  .mh-90-md {
    margin-left: px(90) !important;
    margin-right: px(90) !important;
  }

  .mh-95-md {
    margin-left: px(95) !important;
    margin-right: px(95) !important;
  }

  .mh-100-md {
    margin-left: px(100) !important;
    margin-right: px(100) !important;
  }
  // Margin :: Top
  .mt-0-md {
    margin-top: px(0) !important;
  }

  .mt-5-md {
    margin-top: px(5) !important;
  }

  .mt-10-md {
    margin-top: px(10) !important;
  }

  .mt-15-md {
    margin-top: px(15) !important;
  }

  .mt-20-md {
    margin-top: px(20) !important;
  }

  .mt-25-md {
    margin-top: px(25) !important;
  }

  .mt-30-md {
    margin-top: px(30) !important;
  }

  .mt-35-md {
    margin-top: px(35) !important;
  }

  .mt-40-md {
    margin-top: px(40) !important;
  }

  .mt-45-md {
    margin-top: px(45) !important;
  }

  .mt-50-md {
    margin-top: px(50) !important;
  }

  .mt-55-md {
    margin-top: px(55) !important;
  }

  .mt-60-md {
    margin-top: px(60) !important;
  }

  .mt-65-md {
    margin-top: px(65) !important;
  }

  .mt-70-md {
    margin-top: px(70) !important;
  }

  .mt-75-md {
    margin-top: px(75) !important;
  }

  .mt-80-md {
    margin-top: px(80) !important;
  }

  .mt-85-md {
    margin-top: px(85) !important;
  }

  .mt-90-md {
    margin-top: px(90) !important;
  }

  .mt-95-md {
    margin-top: px(95) !important;
  }

  .mt-100-md {
    margin-top: px(100) !important;
  }
  // Margin :: Right
  .mr-0-md {
    margin-right: px(0) !important;
  }

  .mr-5-md {
    margin-right: px(5) !important;
  }

  .mr-10-md {
    margin-right: px(10) !important;
  }

  .mr-15-md {
    margin-right: px(15) !important;
  }

  .mr-20-md {
    margin-right: px(20) !important;
  }

  .mr-25-md {
    margin-right: px(25) !important;
  }

  .mr-30-md {
    margin-right: px(30) !important;
  }

  .mr-35-md {
    margin-right: px(35) !important;
  }

  .mr-40-md {
    margin-right: px(40) !important;
  }

  .mr-45-md {
    margin-right: px(45) !important;
  }

  .mr-50-md {
    margin-right: px(50) !important;
  }

  .mr-55-md {
    margin-right: px(55) !important;
  }

  .mr-60-md {
    margin-right: px(60) !important;
  }

  .mr-65-md {
    margin-right: px(65) !important;
  }

  .mr-70-md {
    margin-right: px(70) !important;
  }

  .mr-75-md {
    margin-right: px(75) !important;
  }

  .mr-80-md {
    margin-right: px(80) !important;
  }

  .mr-85-md {
    margin-right: px(85) !important;
  }

  .mr-90-md {
    margin-right: px(90) !important;
  }

  .mr-95-md {
    margin-right: px(95) !important;
  }

  .mr-100-md {
    margin-right: px(100) !important;
  }
  // Margin :: Bottom
  .mb-0-md {
    margin-bottom: px(0) !important;
  }

  .mb-5-md {
    margin-bottom: px(5) !important;
  }

  .mb-10-md {
    margin-bottom: px(10) !important;
  }

  .mb-15-md {
    margin-bottom: px(15) !important;
  }

  .mb-20-md {
    margin-bottom: px(20) !important;
  }

  .mb-25-md {
    margin-bottom: px(25) !important;
  }

  .mb-30-md {
    margin-bottom: px(30) !important;
  }

  .mb-35-md {
    margin-bottom: px(35) !important;
  }

  .mb-40-md {
    margin-bottom: px(40) !important;
  }

  .mb-45-md {
    margin-bottom: px(45) !important;
  }

  .mb-50-md {
    margin-bottom: px(50) !important;
  }

  .mb-55-md {
    margin-bottom: px(55) !important;
  }

  .mb-60-md {
    margin-bottom: px(60) !important;
  }

  .mb-65-md {
    margin-bottom: px(65) !important;
  }

  .mb-70-md {
    margin-bottom: px(70) !important;
  }

  .mb-75-md {
    margin-bottom: px(75) !important;
  }

  .mb-80-md {
    margin-bottom: px(80) !important;
  }

  .mb-85-md {
    margin-bottom: px(85) !important;
  }

  .mb-90-md {
    margin-bottom: px(90) !important;
  }

  .mb-95-md {
    margin-bottom: px(95) !important;
  }

  .mb-100-md {
    margin-bottom: px(100) !important;
  }
  // Margin :: Left
  .ml-0-md {
    margin-left: px(0) !important;
  }

  .ml-5-md {
    margin-left: px(5) !important;
  }

  .ml-10-md {
    margin-left: px(10) !important;
  }

  .ml-15-md {
    margin-left: px(15) !important;
  }

  .ml-20-md {
    margin-left: px(20) !important;
  }

  .ml-25-md {
    margin-left: px(25) !important;
  }

  .ml-30-md {
    margin-left: px(30) !important;
  }

  .ml-35-md {
    margin-left: px(35) !important;
  }

  .ml-40-md {
    margin-left: px(40) !important;
  }

  .ml-45-md {
    margin-left: px(45) !important;
  }

  .ml-50-md {
    margin-left: px(50) !important;
  }

  .ml-55-md {
    margin-left: px(55) !important;
  }

  .ml-60-md {
    margin-left: px(60) !important;
  }

  .ml-65-md {
    margin-left: px(65) !important;
  }

  .ml-70-md {
    margin-left: px(70) !important;
  }

  .ml-75-md {
    margin-left: px(75) !important;
  }

  .ml-80-md {
    margin-left: px(80) !important;
  }

  .ml-85-md {
    margin-left: px(85) !important;
  }

  .ml-90-md {
    margin-left: px(90) !important;
  }

  .ml-95-md {
    margin-left: px(95) !important;
  }

  .ml-100-md {
    margin-left: px(100) !important;
  }
  // Padding :: All
  .pa-0-md {
    padding: px(0) !important;
  }

  .pa-5-md {
    padding: px(5) !important;
  }

  .pa-10-md {
    padding: px(10) !important;
  }

  .pa-15-md {
    padding: px(15) !important;
  }

  .pa-20-md {
    padding: px(20) !important;
  }

  .pa-25-md {
    padding: px(25) !important;
  }

  .pa-30-md {
    padding: px(30) !important;
  }

  .pa-35-md {
    padding: px(35) !important;
  }

  .pa-40-md {
    padding: px(40) !important;
  }

  .pa-45-md {
    padding: px(45) !important;
  }

  .pa-50-md {
    padding: px(50) !important;
  }

  .pa-55-md {
    padding: px(55) !important;
  }

  .pa-60-md {
    padding: px(60) !important;
  }

  .pa-65-md {
    padding: px(65) !important;
  }

  .pa-70-md {
    padding: px(70) !important;
  }

  .pa-75-md {
    padding: px(75) !important;
  }

  .pa-80-md {
    padding: px(80) !important;
  }

  .pa-85-md {
    padding: px(85) !important;
  }

  .pa-90-md {
    padding: px(90) !important;
  }

  .pa-95-md {
    padding: px(95) !important;
  }

  .pa-100-md {
    padding: px(100) !important;
  }
  // Padding :: Vertical
  .pv-0-md {
    padding-top: px(0) !important;
    padding-bottom: px(0) !important;
  }

  .pv-5-md {
    padding-top: px(5) !important;
    padding-bottom: px(5) !important;
  }

  .pv-10-md {
    padding-top: px(10) !important;
    padding-bottom: px(10) !important;
  }

  .pv-15-md {
    padding-top: px(15) !important;
    padding-bottom: px(15) !important;
  }

  .pv-20-md {
    padding-top: px(20) !important;
    padding-bottom: px(20) !important;
  }

  .pv-25-md {
    padding-top: px(25) !important;
    padding-bottom: px(25) !important;
  }

  .pv-30-md {
    padding-top: px(30) !important;
    padding-bottom: px(30) !important;
  }

  .pv-35-md {
    padding-top: px(35) !important;
    padding-bottom: px(35) !important;
  }

  .pv-40-md {
    padding-top: px(40) !important;
    padding-bottom: px(40) !important;
  }

  .pv-45-md {
    padding-top: px(45) !important;
    padding-bottom: px(45) !important;
  }

  .pv-50-md {
    padding-top: px(50) !important;
    padding-bottom: px(50) !important;
  }

  .pv-55-md {
    padding-top: px(55) !important;
    padding-bottom: px(55) !important;
  }

  .pv-60-md {
    padding-top: px(60) !important;
    padding-bottom: px(60) !important;
  }

  .pv-65-md {
    padding-top: px(65) !important;
    padding-bottom: px(65) !important;
  }

  .pv-70-md {
    padding-top: px(70) !important;
    padding-bottom: px(70) !important;
  }

  .pv-75-md {
    padding-top: px(75) !important;
    padding-bottom: px(75) !important;
  }

  .pv-80-md {
    padding-top: px(80) !important;
    padding-bottom: px(80) !important;
  }

  .pv-85-md {
    padding-top: px(85) !important;
    padding-bottom: px(85) !important;
  }

  .pv-90-md {
    padding-top: px(90) !important;
    padding-bottom: px(90) !important;
  }

  .pv-95-md {
    padding-top: px(95) !important;
    padding-bottom: px(95) !important;
  }

  .pv-100-md {
    padding-top: px(100) !important;
    padding-bottom: px(100) !important;
  }
  // Padding :: Horizontal
  .ph-0-md {
    padding-left: px(0) !important;
    padding-right: px(0) !important;
  }

  .ph-5-md {
    padding-left: px(5) !important;
    padding-right: px(5) !important;
  }

  .ph-10-md {
    padding-left: px(10) !important;
    padding-right: px(10) !important;
  }

  .ph-15-md {
    padding-left: px(15) !important;
    padding-right: px(15) !important;
  }

  .ph-20-md {
    padding-left: px(20) !important;
    padding-right: px(20) !important;
  }

  .ph-25-md {
    padding-left: px(25) !important;
    padding-right: px(25) !important;
  }

  .ph-30-md {
    padding-left: px(30) !important;
    padding-right: px(30) !important;
  }

  .ph-35-md {
    padding-left: px(35) !important;
    padding-right: px(35) !important;
  }

  .ph-40-md {
    padding-left: px(40) !important;
    padding-right: px(40) !important;
  }

  .ph-45-md {
    padding-left: px(45) !important;
    padding-right: px(45) !important;
  }

  .ph-50-md {
    padding-left: px(50) !important;
    padding-right: px(50) !important;
  }

  .ph-55-md {
    padding-left: px(55) !important;
    padding-right: px(55) !important;
  }

  .ph-60-md {
    padding-left: px(60) !important;
    padding-right: px(60) !important;
  }

  .ph-65-md {
    padding-left: px(65) !important;
    padding-right: px(65) !important;
  }

  .ph-70-md {
    padding-left: px(70) !important;
    padding-right: px(70) !important;
  }

  .ph-75-md {
    padding-left: px(75) !important;
    padding-right: px(75) !important;
  }

  .ph-80-md {
    padding-left: px(80) !important;
    padding-right: px(80) !important;
  }

  .ph-85-md {
    padding-left: px(85) !important;
    padding-right: px(85) !important;
  }

  .ph-90-md {
    padding-left: px(90) !important;
    padding-right: px(90) !important;
  }

  .ph-95-md {
    padding-left: px(95) !important;
    padding-right: px(95) !important;
  }

  .ph-100-md {
    padding-left: px(100) !important;
    padding-right: px(100) !important;
  }
  // Padding :: Top
  .pt-0-md {
    padding-top: px(0) !important;
  }

  .pt-5-md {
    padding-top: px(5) !important;
  }

  .pt-10-md {
    padding-top: px(10) !important;
  }

  .pt-15-md {
    padding-top: px(15) !important;
  }

  .pt-20-md {
    padding-top: px(20) !important;
  }

  .pt-25-md {
    padding-top: px(25) !important;
  }

  .pt-30-md {
    padding-top: px(30) !important;
  }

  .pt-35-md {
    padding-top: px(35) !important;
  }

  .pt-40-md {
    padding-top: px(40) !important;
  }

  .pt-45-md {
    padding-top: px(45) !important;
  }

  .pt-50-md {
    padding-top: px(50) !important;
  }

  .pt-55-md {
    padding-top: px(55) !important;
  }

  .pt-60-md {
    padding-top: px(60) !important;
  }

  .pt-65-md {
    padding-top: px(65) !important;
  }

  .pt-70-md {
    padding-top: px(70) !important;
  }

  .pt-75-md {
    padding-top: px(75) !important;
  }

  .pt-80-md {
    padding-top: px(80) !important;
  }

  .pt-85-md {
    padding-top: px(85) !important;
  }

  .pt-90-md {
    padding-top: px(90) !important;
  }

  .pt-95-md {
    padding-top: px(95) !important;
  }

  .pt-100-md {
    padding-top: px(100) !important;
  }
  // Padding :: Right
  .pr-0-md {
    padding-right: px(0) !important;
  }

  .pr-5-md {
    padding-right: px(5) !important;
  }

  .pr-10-md {
    padding-right: px(10) !important;
  }

  .pr-15-md {
    padding-right: px(15) !important;
  }

  .pr-20-md {
    padding-right: px(20) !important;
  }

  .pr-25-md {
    padding-right: px(25) !important;
  }

  .pr-30-md {
    padding-right: px(30) !important;
  }

  .pr-35-md {
    padding-right: px(35) !important;
  }

  .pr-40-md {
    padding-right: px(40) !important;
  }

  .pr-45-md {
    padding-right: px(45) !important;
  }

  .pr-50-md {
    padding-right: px(50) !important;
  }

  .pr-55-md {
    padding-right: px(55) !important;
  }

  .pr-60-md {
    padding-right: px(60) !important;
  }

  .pr-65-md {
    padding-right: px(65) !important;
  }

  .pr-70-md {
    padding-right: px(70) !important;
  }

  .pr-75-md {
    padding-right: px(75) !important;
  }

  .pr-80-md {
    padding-right: px(80) !important;
  }

  .pr-85-md {
    padding-right: px(85) !important;
  }

  .pr-90-md {
    padding-right: px(90) !important;
  }

  .pr-95-md {
    padding-right: px(95) !important;
  }

  .pr-100-md {
    padding-right: px(100) !important;
  }
  // Padding :: Bottom
  .pb-0-md {
    padding-bottom: px(0) !important;
  }

  .pb-5-md {
    padding-bottom: px(5) !important;
  }

  .pb-10-md {
    padding-bottom: px(10) !important;
  }

  .pb-15-md {
    padding-bottom: px(15) !important;
  }

  .pb-20-md {
    padding-bottom: px(20) !important;
  }

  .pb-25-md {
    padding-bottom: px(25) !important;
  }

  .pb-30-md {
    padding-bottom: px(30) !important;
  }

  .pb-35-md {
    padding-bottom: px(35) !important;
  }

  .pb-40-md {
    padding-bottom: px(40) !important;
  }

  .pb-45-md {
    padding-bottom: px(45) !important;
  }

  .pb-50-md {
    padding-bottom: px(50) !important;
  }

  .pb-55-md {
    padding-bottom: px(55) !important;
  }

  .pb-60-md {
    padding-bottom: px(60) !important;
  }

  .pb-65-md {
    padding-bottom: px(65) !important;
  }

  .pb-70-md {
    padding-bottom: px(70) !important;
  }

  .pb-75-md {
    padding-bottom: px(75) !important;
  }

  .pb-80-md {
    padding-bottom: px(80) !important;
  }

  .pb-85-md {
    padding-bottom: px(85) !important;
  }

  .pb-90-md {
    padding-bottom: px(90) !important;
  }

  .pb-95-md {
    padding-bottom: px(95) !important;
  }

  .pb-100-md {
    padding-bottom: px(100) !important;
  }
  // Padding :: Left
  .pl-0-md {
    padding-left: px(0) !important;
  }

  .pl-5-md {
    padding-left: px(5) !important;
  }

  .pl-10-md {
    padding-left: px(10) !important;
  }

  .pl-15-md {
    padding-left: px(15) !important;
  }

  .pl-20-md {
    padding-left: px(20) !important;
  }

  .pl-25-md {
    padding-left: px(25) !important;
  }

  .pl-30-md {
    padding-left: px(30) !important;
  }

  .pl-35-md {
    padding-left: px(35) !important;
  }

  .pl-40-md {
    padding-left: px(40) !important;
  }

  .pl-45-md {
    padding-left: px(45) !important;
  }

  .pl-50-md {
    padding-left: px(50) !important;
  }

  .pl-55-md {
    padding-left: px(55) !important;
  }

  .pl-60-md {
    padding-left: px(60) !important;
  }

  .pl-65-md {
    padding-left: px(65) !important;
  }

  .pl-70-md {
    padding-left: px(70) !important;
  }

  .pl-75-md {
    padding-left: px(75) !important;
  }

  .pl-80-md {
    padding-left: px(80) !important;
  }

  .pl-85-md {
    padding-left: px(85) !important;
  }

  .pl-90-md {
    padding-left: px(90) !important;
  }

  .pl-95-md {
    padding-left: px(95) !important;
  }

  .pl-100-md {
    padding-left: px(100) !important;
  }
}
@include media-breakpoint-down(sm) {
  // Margin :: All
  .ma-0-sm {
    margin: px(0)!important !important;
  }

  .ma-5-sm {
    margin: px(5) !important;
  }

  .ma-10-sm {
    margin: px(10) !important;
  }

  .ma-15-sm {
    margin: px(15) !important;
  }

  .ma-20-sm {
    margin: px(20) !important;
  }

  .ma-25-sm {
    margin: px(25) !important;
  }

  .ma-30-sm {
    margin: px(30) !important;
  }

  .ma-35-sm {
    margin: px(35) !important;
  }

  .ma-40-sm {
    margin: px(40) !important;
  }

  .ma-45-sm {
    margin: px(45) !important;
  }

  .ma-50-sm {
    margin: px(50) !important;
  }

  .ma-55-sm {
    margin: px(55) !important;
  }

  .ma-60-sm {
    margin: px(60) !important;
  }

  .ma-65-sm {
    margin: px(65) !important;
  }

  .ma-70-sm {
    margin: px(70) !important;
  }

  .ma-75-sm {
    margin: px(75) !important;
  }

  .ma-80-sm {
    margin: px(80) !important;
  }

  .ma-85-sm {
    margin: px(85) !important;
  }

  .ma-90-sm {
    margin: px(90) !important;
  }

  .ma-95-sm {
    margin: px(95) !important;
  }

  .ma-100-sm {
    margin: px(100) !important;
  }
  // Margin :: Vertical
  .mv-0-sm {
    margin-top: px(0) !important;
    margin-bottom: px(0) !important;
  }

  .mv-5-sm {
    margin-top: px(5) !important;
    margin-bottom: px(5) !important;
  }

  .mv-10-sm {
    margin-top: px(10) !important;
    margin-bottom: px(10) !important;
  }

  .mv-15-sm {
    margin-top: px(15) !important;
    margin-bottom: px(15) !important;
  }

  .mv-20-sm {
    margin-top: px(20) !important;
    margin-bottom: px(20) !important;
  }

  .mv-25-sm {
    margin-top: px(25) !important;
    margin-bottom: px(25) !important;
  }

  .mv-30-sm {
    margin-top: px(30) !important;
    margin-bottom: px(30) !important;
  }

  .mv-35-sm {
    margin-top: px(35) !important;
    margin-bottom: px(35) !important;
  }

  .mv-40-sm {
    margin-top: px(40) !important;
    margin-bottom: px(40) !important;
  }

  .mv-45-sm {
    margin-top: px(45) !important;
    margin-bottom: px(45) !important;
  }

  .mv-50-sm {
    margin-top: px(50) !important;
    margin-bottom: px(50) !important;
  }

  .mv-55-sm {
    margin-top: px(55) !important;
    margin-bottom: px(55) !important;
  }

  .mv-60-sm {
    margin-top: px(60) !important;
    margin-bottom: px(60) !important;
  }

  .mv-65-sm {
    margin-top: px(65) !important;
    margin-bottom: px(65) !important;
  }

  .mv-70-sm {
    margin-top: px(70) !important;
    margin-bottom: px(70) !important;
  }

  .mv-75-sm {
    margin-top: px(75) !important;
    margin-bottom: px(75) !important;
  }

  .mv-80-sm {
    margin-top: px(80) !important;
    margin-bottom: px(80) !important;
  }

  .mv-85-sm {
    margin-top: px(85) !important;
    margin-bottom: px(85) !important;
  }

  .mv-90-sm {
    margin-top: px(90) !important;
    margin-bottom: px(90) !important;
  }

  .mv-95-sm {
    margin-top: px(95) !important;
    margin-bottom: px(95) !important;
  }

  .mv-100-sm {
    margin-top: px(100) !important;
    margin-bottom: px(100) !important;
  }
  // Margin :: Horizontal
  .mh-0-sm {
    margin-left: px(0) !important;
    margin-right: px(0) !important;
  }

  .mh-5-sm {
    margin-left: px(5) !important;
    margin-right: px(5) !important;
  }

  .mh-10-sm {
    margin-left: px(10) !important;
    margin-right: px(10) !important;
  }

  .mh-15-sm {
    margin-left: px(15) !important;
    margin-right: px(15) !important;
  }

  .mh-20-sm {
    margin-left: px(20) !important;
    margin-right: px(20) !important;
  }

  .mh-25-sm {
    margin-left: px(25) !important;
    margin-right: px(25) !important;
  }

  .mh-30-sm {
    margin-left: px(30) !important;
    margin-right: px(30) !important;
  }

  .mh-35-sm {
    margin-left: px(35) !important;
    margin-right: px(35) !important;
  }

  .mh-40-sm {
    margin-left: px(40) !important;
    margin-right: px(40) !important;
  }

  .mh-45-sm {
    margin-left: px(45) !important;
    margin-right: px(45) !important;
  }

  .mh-50-sm {
    margin-left: px(50) !important;
    margin-right: px(50) !important;
  }

  .mh-55-sm {
    margin-left: px(55) !important;
    margin-right: px(55) !important;
  }

  .mh-60-sm {
    margin-left: px(60) !important;
    margin-right: px(60) !important;
  }

  .mh-65-sm {
    margin-left: px(65) !important;
    margin-right: px(65) !important;
  }

  .mh-70-sm {
    margin-left: px(70) !important;
    margin-right: px(70) !important;
  }

  .mh-75-sm {
    margin-left: px(75) !important;
    margin-right: px(75) !important;
  }

  .mh-80-sm {
    margin-left: px(80) !important;
    margin-right: px(80) !important;
  }

  .mh-85-sm {
    margin-left: px(85) !important;
    margin-right: px(85) !important;
  }

  .mh-90-sm {
    margin-left: px(90) !important;
    margin-right: px(90) !important;
  }

  .mh-95-sm {
    margin-left: px(95) !important;
    margin-right: px(95) !important;
  }

  .mh-100-sm {
    margin-left: px(100) !important;
    margin-right: px(100) !important;
  }
  // Margin :: Top
  .mt-0-sm {
    margin-top: px(0) !important;
  }

  .mt-5-sm {
    margin-top: px(5) !important;
  }

  .mt-10-sm {
    margin-top: px(10) !important;
  }

  .mt-15-sm {
    margin-top: px(15) !important;
  }

  .mt-20-sm {
    margin-top: px(20) !important;
  }

  .mt-25-sm {
    margin-top: px(25) !important;
  }

  .mt-30-sm {
    margin-top: px(30) !important;
  }

  .mt-35-sm {
    margin-top: px(35) !important;
  }

  .mt-40-sm {
    margin-top: px(40) !important;
  }

  .mt-45-sm {
    margin-top: px(45) !important;
  }

  .mt-50-sm {
    margin-top: px(50) !important;
  }

  .mt-55-sm {
    margin-top: px(55) !important;
  }

  .mt-60-sm {
    margin-top: px(60) !important;
  }

  .mt-65-sm {
    margin-top: px(65) !important;
  }

  .mt-70-sm {
    margin-top: px(70) !important;
  }

  .mt-75-sm {
    margin-top: px(75) !important;
  }

  .mt-80-sm {
    margin-top: px(80) !important;
  }

  .mt-85-sm {
    margin-top: px(85) !important;
  }

  .mt-90-sm {
    margin-top: px(90) !important;
  }

  .mt-95-sm {
    margin-top: px(95) !important;
  }

  .mt-100-sm {
    margin-top: px(100) !important;
  }
  // Margin :: Right
  .mr-0-sm {
    margin-right: px(0) !important;
  }

  .mr-5-sm {
    margin-right: px(5) !important;
  }

  .mr-10-sm {
    margin-right: px(10) !important;
  }

  .mr-15-sm {
    margin-right: px(15) !important;
  }

  .mr-20-sm {
    margin-right: px(20) !important;
  }

  .mr-25-sm {
    margin-right: px(25) !important;
  }

  .mr-30-sm {
    margin-right: px(30) !important;
  }

  .mr-35-sm {
    margin-right: px(35) !important;
  }

  .mr-40-sm {
    margin-right: px(40) !important;
  }

  .mr-45-sm {
    margin-right: px(45) !important;
  }

  .mr-50-sm {
    margin-right: px(50) !important;
  }

  .mr-55-sm {
    margin-right: px(55) !important;
  }

  .mr-60-sm {
    margin-right: px(60) !important;
  }

  .mr-65-sm {
    margin-right: px(65) !important;
  }

  .mr-70-sm {
    margin-right: px(70) !important;
  }

  .mr-75-sm {
    margin-right: px(75) !important;
  }

  .mr-80-sm {
    margin-right: px(80) !important;
  }

  .mr-85-sm {
    margin-right: px(85) !important;
  }

  .mr-90-sm {
    margin-right: px(90) !important;
  }

  .mr-95-sm {
    margin-right: px(95) !important;
  }

  .mr-100-sm {
    margin-right: px(100) !important;
  }
  // Margin :: Bottom
  .mb-0-sm {
    margin-bottom: px(0) !important;
  }

  .mb-5-sm {
    margin-bottom: px(5) !important;
  }

  .mb-10-sm {
    margin-bottom: px(10) !important;
  }

  .mb-15-sm {
    margin-bottom: px(15) !important;
  }

  .mb-20-sm {
    margin-bottom: px(20) !important;
  }

  .mb-25-sm {
    margin-bottom: px(25) !important;
  }

  .mb-30-sm {
    margin-bottom: px(30) !important;
  }

  .mb-35-sm {
    margin-bottom: px(35) !important;
  }

  .mb-40-sm {
    margin-bottom: px(40) !important;
  }

  .mb-45-sm {
    margin-bottom: px(45) !important;
  }

  .mb-50-sm {
    margin-bottom: px(50) !important;
  }

  .mb-55-sm {
    margin-bottom: px(55) !important;
  }

  .mb-60-sm {
    margin-bottom: px(60) !important;
  }

  .mb-65-sm {
    margin-bottom: px(65) !important;
  }

  .mb-70-sm {
    margin-bottom: px(70) !important;
  }

  .mb-75-sm {
    margin-bottom: px(75) !important;
  }

  .mb-80-sm {
    margin-bottom: px(80) !important;
  }

  .mb-85-sm {
    margin-bottom: px(85) !important;
  }

  .mb-90-sm {
    margin-bottom: px(90) !important;
  }

  .mb-95-sm {
    margin-bottom: px(95) !important;
  }

  .mb-100-sm {
    margin-bottom: px(100) !important;
  }
  // Margin :: Left
  .ml-0-sm {
    margin-left: px(0) !important;
  }

  .ml-5-sm {
    margin-left: px(5) !important;
  }

  .ml-10-sm {
    margin-left: px(10) !important;
  }

  .ml-15-sm {
    margin-left: px(15) !important;
  }

  .ml-20-sm {
    margin-left: px(20) !important;
  }

  .ml-25-sm {
    margin-left: px(25) !important;
  }

  .ml-30-sm {
    margin-left: px(30) !important;
  }

  .ml-35-sm {
    margin-left: px(35) !important;
  }

  .ml-40-sm {
    margin-left: px(40) !important;
  }

  .ml-45-sm {
    margin-left: px(45) !important;
  }

  .ml-50-sm {
    margin-left: px(50) !important;
  }

  .ml-55-sm {
    margin-left: px(55) !important;
  }

  .ml-60-sm {
    margin-left: px(60) !important;
  }

  .ml-65-sm {
    margin-left: px(65) !important;
  }

  .ml-70-sm {
    margin-left: px(70) !important;
  }

  .ml-75-sm {
    margin-left: px(75) !important;
  }

  .ml-80-sm {
    margin-left: px(80) !important;
  }

  .ml-85-sm {
    margin-left: px(85) !important;
  }

  .ml-90-sm {
    margin-left: px(90) !important;
  }

  .ml-95-sm {
    margin-left: px(95) !important;
  }

  .ml-100-sm {
    margin-left: px(100) !important;
  }
  // Padding :: All
  .pa-0-sm {
    padding: px(0) !important;
  }

  .pa-5-sm {
    padding: px(5) !important;
  }

  .pa-10-sm {
    padding: px(10) !important;
  }

  .pa-15-sm {
    padding: px(15) !important;
  }

  .pa-20-sm {
    padding: px(20) !important;
  }

  .pa-25-sm {
    padding: px(25) !important;
  }

  .pa-30-sm {
    padding: px(30) !important;
  }

  .pa-35-sm {
    padding: px(35) !important;
  }

  .pa-40-sm {
    padding: px(40) !important;
  }

  .pa-45-sm {
    padding: px(45) !important;
  }

  .pa-50-sm {
    padding: px(50) !important;
  }

  .pa-55-sm {
    padding: px(55) !important;
  }

  .pa-60-sm {
    padding: px(60) !important;
  }

  .pa-65-sm {
    padding: px(65) !important;
  }

  .pa-70-sm {
    padding: px(70) !important;
  }

  .pa-75-sm {
    padding: px(75) !important;
  }

  .pa-80-sm {
    padding: px(80) !important;
  }

  .pa-85-sm {
    padding: px(85) !important;
  }

  .pa-90-sm {
    padding: px(90) !important;
  }

  .pa-95-sm {
    padding: px(95) !important;
  }

  .pa-100-sm {
    padding: px(100) !important;
  }
  // Padding :: Vertical
  .pv-0-sm {
    padding-top: px(0) !important;
    padding-bottom: px(0) !important;
  }

  .pv-5-sm {
    padding-top: px(5) !important;
    padding-bottom: px(5) !important;
  }

  .pv-10-sm {
    padding-top: px(10) !important;
    padding-bottom: px(10) !important;
  }

  .pv-15-sm {
    padding-top: px(15) !important;
    padding-bottom: px(15) !important;
  }

  .pv-20-sm {
    padding-top: px(20) !important;
    padding-bottom: px(20) !important;
  }

  .pv-25-sm {
    padding-top: px(25) !important;
    padding-bottom: px(25) !important;
  }

  .pv-30-sm {
    padding-top: px(30) !important;
    padding-bottom: px(30) !important;
  }

  .pv-35-sm {
    padding-top: px(35) !important;
    padding-bottom: px(35) !important;
  }

  .pv-40-sm {
    padding-top: px(40) !important;
    padding-bottom: px(40) !important;
  }

  .pv-45-sm {
    padding-top: px(45) !important;
    padding-bottom: px(45) !important;
  }

  .pv-50-sm {
    padding-top: px(50) !important;
    padding-bottom: px(50) !important;
  }

  .pv-55-sm {
    padding-top: px(55) !important;
    padding-bottom: px(55) !important;
  }

  .pv-60-sm {
    padding-top: px(60) !important;
    padding-bottom: px(60) !important;
  }

  .pv-65-sm {
    padding-top: px(65) !important;
    padding-bottom: px(65) !important;
  }

  .pv-70-sm {
    padding-top: px(70) !important;
    padding-bottom: px(70) !important;
  }

  .pv-75-sm {
    padding-top: px(75) !important;
    padding-bottom: px(75) !important;
  }

  .pv-80-sm {
    padding-top: px(80) !important;
    padding-bottom: px(80) !important;
  }

  .pv-85-sm {
    padding-top: px(85) !important;
    padding-bottom: px(85) !important;
  }

  .pv-90-sm {
    padding-top: px(90) !important;
    padding-bottom: px(90) !important;
  }

  .pv-95-sm {
    padding-top: px(95) !important;
    padding-bottom: px(95) !important;
  }

  .pv-100-sm {
    padding-top: px(100) !important;
    padding-bottom: px(100) !important;
  }
  // Padding :: Horizontal
  .ph-0-sm {
    padding-left: px(0) !important;
    padding-right: px(0) !important;
  }

  .ph-5-sm {
    padding-left: px(5) !important;
    padding-right: px(5) !important;
  }

  .ph-10-sm {
    padding-left: px(10) !important;
    padding-right: px(10) !important;
  }

  .ph-15-sm {
    padding-left: px(15) !important;
    padding-right: px(15) !important;
  }

  .ph-20-sm {
    padding-left: px(20) !important;
    padding-right: px(20) !important;
  }

  .ph-25-sm {
    padding-left: px(25) !important;
    padding-right: px(25) !important;
  }

  .ph-30-sm {
    padding-left: px(30) !important;
    padding-right: px(30) !important;
  }

  .ph-35-sm {
    padding-left: px(35) !important;
    padding-right: px(35) !important;
  }

  .ph-40-sm {
    padding-left: px(40) !important;
    padding-right: px(40) !important;
  }

  .ph-45-sm {
    padding-left: px(45) !important;
    padding-right: px(45) !important;
  }

  .ph-50-sm {
    padding-left: px(50) !important;
    padding-right: px(50) !important;
  }

  .ph-55-sm {
    padding-left: px(55) !important;
    padding-right: px(55) !important;
  }

  .ph-60-sm {
    padding-left: px(60) !important;
    padding-right: px(60) !important;
  }

  .ph-65-sm {
    padding-left: px(65) !important;
    padding-right: px(65) !important;
  }

  .ph-70-sm {
    padding-left: px(70) !important;
    padding-right: px(70) !important;
  }

  .ph-75-sm {
    padding-left: px(75) !important;
    padding-right: px(75) !important;
  }

  .ph-80-sm {
    padding-left: px(80) !important;
    padding-right: px(80) !important;
  }

  .ph-85-sm {
    padding-left: px(85) !important;
    padding-right: px(85) !important;
  }

  .ph-90-sm {
    padding-left: px(90) !important;
    padding-right: px(90) !important;
  }

  .ph-95-sm {
    padding-left: px(95) !important;
    padding-right: px(95) !important;
  }

  .ph-100-sm {
    padding-left: px(100) !important;
    padding-right: px(100) !important;
  }
  // Padding :: Top
  .pt-0-sm {
    padding-top: px(0) !important;
  }

  .pt-5-sm {
    padding-top: px(5) !important;
  }

  .pt-10-sm {
    padding-top: px(10) !important;
  }

  .pt-15-sm {
    padding-top: px(15) !important;
  }

  .pt-20-sm {
    padding-top: px(20) !important;
  }

  .pt-25-sm {
    padding-top: px(25) !important;
  }

  .pt-30-sm {
    padding-top: px(30) !important;
  }

  .pt-35-sm {
    padding-top: px(35) !important;
  }

  .pt-40-sm {
    padding-top: px(40) !important;
  }

  .pt-45-sm {
    padding-top: px(45) !important;
  }

  .pt-50-sm {
    padding-top: px(50) !important;
  }

  .pt-55-sm {
    padding-top: px(55) !important;
  }

  .pt-60-sm {
    padding-top: px(60) !important;
  }

  .pt-65-sm {
    padding-top: px(65) !important;
  }

  .pt-70-sm {
    padding-top: px(70) !important;
  }

  .pt-75-sm {
    padding-top: px(75) !important;
  }

  .pt-80-sm {
    padding-top: px(80) !important;
  }

  .pt-85-sm {
    padding-top: px(85) !important;
  }

  .pt-90-sm {
    padding-top: px(90) !important;
  }

  .pt-95-sm {
    padding-top: px(95) !important;
  }

  .pt-100-sm {
    padding-top: px(100) !important;
  }
  // Padding :: Right
  .pr-0-sm {
    padding-right: px(0) !important;
  }

  .pr-5-sm {
    padding-right: px(5) !important;
  }

  .pr-10-sm {
    padding-right: px(10) !important;
  }

  .pr-15-sm {
    padding-right: px(15) !important;
  }

  .pr-20-sm {
    padding-right: px(20) !important;
  }

  .pr-25-sm {
    padding-right: px(25) !important;
  }

  .pr-30-sm {
    padding-right: px(30) !important;
  }

  .pr-35-sm {
    padding-right: px(35) !important;
  }

  .pr-40-sm {
    padding-right: px(40) !important;
  }

  .pr-45-sm {
    padding-right: px(45) !important;
  }

  .pr-50-sm {
    padding-right: px(50) !important;
  }

  .pr-55-sm {
    padding-right: px(55) !important;
  }

  .pr-60-sm {
    padding-right: px(60) !important;
  }

  .pr-65-sm {
    padding-right: px(65) !important;
  }

  .pr-70-sm {
    padding-right: px(70) !important;
  }

  .pr-75-sm {
    padding-right: px(75) !important;
  }

  .pr-80-sm {
    padding-right: px(80) !important;
  }

  .pr-85-sm {
    padding-right: px(85) !important;
  }

  .pr-90-sm {
    padding-right: px(90) !important;
  }

  .pr-95-sm {
    padding-right: px(95) !important;
  }

  .pr-100-sm {
    padding-right: px(100) !important;
  }
  // Padding :: Bottom
  .pb-0-sm {
    padding-bottom: px(0) !important;
  }

  .pb-5-sm {
    padding-bottom: px(5) !important;
  }

  .pb-10-sm {
    padding-bottom: px(10) !important;
  }

  .pb-15-sm {
    padding-bottom: px(15) !important;
  }

  .pb-20-sm {
    padding-bottom: px(20) !important;
  }

  .pb-25-sm {
    padding-bottom: px(25) !important;
  }

  .pb-30-sm {
    padding-bottom: px(30) !important;
  }

  .pb-35-sm {
    padding-bottom: px(35) !important;
  }

  .pb-40-sm {
    padding-bottom: px(40) !important;
  }

  .pb-45-sm {
    padding-bottom: px(45) !important;
  }

  .pb-50-sm {
    padding-bottom: px(50) !important;
  }

  .pb-55-sm {
    padding-bottom: px(55) !important;
  }

  .pb-60-sm {
    padding-bottom: px(60) !important;
  }

  .pb-65-sm {
    padding-bottom: px(65) !important;
  }

  .pb-70-sm {
    padding-bottom: px(70) !important;
  }

  .pb-75-sm {
    padding-bottom: px(75) !important;
  }

  .pb-80-sm {
    padding-bottom: px(80) !important;
  }

  .pb-85-sm {
    padding-bottom: px(85) !important;
  }

  .pb-90-sm {
    padding-bottom: px(90) !important;
  }

  .pb-95-sm {
    padding-bottom: px(95) !important;
  }

  .pb-100-sm {
    padding-bottom: px(100) !important;
  }
  // Padding :: Left
  .pl-0-sm {
    padding-left: px(0) !important;
  }

  .pl-5-sm {
    padding-left: px(5) !important;
  }

  .pl-10-sm {
    padding-left: px(10) !important;
  }

  .pl-15-sm {
    padding-left: px(15) !important;
  }

  .pl-20-sm {
    padding-left: px(20) !important;
  }

  .pl-25-sm {
    padding-left: px(25) !important;
  }

  .pl-30-sm {
    padding-left: px(30) !important;
  }

  .pl-35-sm {
    padding-left: px(35) !important;
  }

  .pl-40-sm {
    padding-left: px(40) !important;
  }

  .pl-45-sm {
    padding-left: px(45) !important;
  }

  .pl-50-sm {
    padding-left: px(50) !important;
  }

  .pl-55-sm {
    padding-left: px(55) !important;
  }

  .pl-60-sm {
    padding-left: px(60) !important;
  }

  .pl-65-sm {
    padding-left: px(65) !important;
  }

  .pl-70-sm {
    padding-left: px(70) !important;
  }

  .pl-75-sm {
    padding-left: px(75) !important;
  }

  .pl-80-sm {
    padding-left: px(80) !important;
  }

  .pl-85-sm {
    padding-left: px(85) !important;
  }

  .pl-90-sm {
    padding-left: px(90) !important;
  }

  .pl-95-sm {
    padding-left: px(95) !important;
  }

  .pl-100-sm {
    padding-left: px(100) !important;
  }
}
@include media-breakpoint-down(xs) {
  // Margin :: All
  .ma-0-xs {
    margin: px(0)!important !important;
  }

  .ma-5-xs {
    margin: px(5) !important;
  }

  .ma-10-xs {
    margin: px(10) !important;
  }

  .ma-15-xs {
    margin: px(15) !important;
  }

  .ma-20-xs {
    margin: px(20) !important;
  }

  .ma-25-xs {
    margin: px(25) !important;
  }

  .ma-30-xs {
    margin: px(30) !important;
  }

  .ma-35-xs {
    margin: px(35) !important;
  }

  .ma-40-xs {
    margin: px(40) !important;
  }

  .ma-45-xs {
    margin: px(45) !important;
  }

  .ma-50-xs {
    margin: px(50) !important;
  }

  .ma-55-xs {
    margin: px(55) !important;
  }

  .ma-60-xs {
    margin: px(60) !important;
  }

  .ma-65-xs {
    margin: px(65) !important;
  }

  .ma-70-xs {
    margin: px(70) !important;
  }

  .ma-75-xs {
    margin: px(75) !important;
  }

  .ma-80-xs {
    margin: px(80) !important;
  }

  .ma-85-xs {
    margin: px(85) !important;
  }

  .ma-90-xs {
    margin: px(90) !important;
  }

  .ma-95-xs {
    margin: px(95) !important;
  }

  .ma-100-xs {
    margin: px(100) !important;
  }
  // Margin :: Vertical
  .mv-0-xs {
    margin-top: px(0) !important;
    margin-bottom: px(0) !important;
  }

  .mv-5-xs {
    margin-top: px(5) !important;
    margin-bottom: px(5) !important;
  }

  .mv-10-xs {
    margin-top: px(10) !important;
    margin-bottom: px(10) !important;
  }

  .mv-15-xs {
    margin-top: px(15) !important;
    margin-bottom: px(15) !important;
  }

  .mv-20-xs {
    margin-top: px(20) !important;
    margin-bottom: px(20) !important;
  }

  .mv-25-xs {
    margin-top: px(25) !important;
    margin-bottom: px(25) !important;
  }

  .mv-30-xs {
    margin-top: px(30) !important;
    margin-bottom: px(30) !important;
  }

  .mv-35-xs {
    margin-top: px(35) !important;
    margin-bottom: px(35) !important;
  }

  .mv-40-xs {
    margin-top: px(40) !important;
    margin-bottom: px(40) !important;
  }

  .mv-45-xs {
    margin-top: px(45) !important;
    margin-bottom: px(45) !important;
  }

  .mv-50-xs {
    margin-top: px(50) !important;
    margin-bottom: px(50) !important;
  }

  .mv-55-xs {
    margin-top: px(55) !important;
    margin-bottom: px(55) !important;
  }

  .mv-60-xs {
    margin-top: px(60) !important;
    margin-bottom: px(60) !important;
  }

  .mv-65-xs {
    margin-top: px(65) !important;
    margin-bottom: px(65) !important;
  }

  .mv-70-xs {
    margin-top: px(70) !important;
    margin-bottom: px(70) !important;
  }

  .mv-75-xs {
    margin-top: px(75) !important;
    margin-bottom: px(75) !important;
  }

  .mv-80-xs {
    margin-top: px(80) !important;
    margin-bottom: px(80) !important;
  }

  .mv-85-xs {
    margin-top: px(85) !important;
    margin-bottom: px(85) !important;
  }

  .mv-90-xs {
    margin-top: px(90) !important;
    margin-bottom: px(90) !important;
  }

  .mv-95-xs {
    margin-top: px(95) !important;
    margin-bottom: px(95) !important;
  }

  .mv-100-xs {
    margin-top: px(100) !important;
    margin-bottom: px(100) !important;
  }
  // Margin :: Horizontal
  .mh-0-xs {
    margin-left: px(0) !important;
    margin-right: px(0) !important;
  }

  .mh-5-xs {
    margin-left: px(5) !important;
    margin-right: px(5) !important;
  }

  .mh-10-xs {
    margin-left: px(10) !important;
    margin-right: px(10) !important;
  }

  .mh-15-xs {
    margin-left: px(15) !important;
    margin-right: px(15) !important;
  }

  .mh-20-xs {
    margin-left: px(20) !important;
    margin-right: px(20) !important;
  }

  .mh-25-xs {
    margin-left: px(25) !important;
    margin-right: px(25) !important;
  }

  .mh-30-xs {
    margin-left: px(30) !important;
    margin-right: px(30) !important;
  }

  .mh-35-xs {
    margin-left: px(35) !important;
    margin-right: px(35) !important;
  }

  .mh-40-xs {
    margin-left: px(40) !important;
    margin-right: px(40) !important;
  }

  .mh-45-xs {
    margin-left: px(45) !important;
    margin-right: px(45) !important;
  }

  .mh-50-xs {
    margin-left: px(50) !important;
    margin-right: px(50) !important;
  }

  .mh-55-xs {
    margin-left: px(55) !important;
    margin-right: px(55) !important;
  }

  .mh-60-xs {
    margin-left: px(60) !important;
    margin-right: px(60) !important;
  }

  .mh-65-xs {
    margin-left: px(65) !important;
    margin-right: px(65) !important;
  }

  .mh-70-xs {
    margin-left: px(70) !important;
    margin-right: px(70) !important;
  }

  .mh-75-xs {
    margin-left: px(75) !important;
    margin-right: px(75) !important;
  }

  .mh-80-xs {
    margin-left: px(80) !important;
    margin-right: px(80) !important;
  }

  .mh-85-xs {
    margin-left: px(85) !important;
    margin-right: px(85) !important;
  }

  .mh-90-xs {
    margin-left: px(90) !important;
    margin-right: px(90) !important;
  }

  .mh-95-xs {
    margin-left: px(95) !important;
    margin-right: px(95) !important;
  }

  .mh-100-xs {
    margin-left: px(100) !important;
    margin-right: px(100) !important;
  }
  // Margin :: Top
  .mt-0-xs {
    margin-top: px(0) !important;
  }

  .mt-5-xs {
    margin-top: px(5) !important;
  }

  .mt-10-xs {
    margin-top: px(10) !important;
  }

  .mt-15-xs {
    margin-top: px(15) !important;
  }

  .mt-20-xs {
    margin-top: px(20) !important;
  }

  .mt-25-xs {
    margin-top: px(25) !important;
  }

  .mt-30-xs {
    margin-top: px(30) !important;
  }

  .mt-35-xs {
    margin-top: px(35) !important;
  }

  .mt-40-xs {
    margin-top: px(40) !important;
  }

  .mt-45-xs {
    margin-top: px(45) !important;
  }

  .mt-50-xs {
    margin-top: px(50) !important;
  }

  .mt-55-xs {
    margin-top: px(55) !important;
  }

  .mt-60-xs {
    margin-top: px(60) !important;
  }

  .mt-65-xs {
    margin-top: px(65) !important;
  }

  .mt-70-xs {
    margin-top: px(70) !important;
  }

  .mt-75-xs {
    margin-top: px(75) !important;
  }

  .mt-80-xs {
    margin-top: px(80) !important;
  }

  .mt-85-xs {
    margin-top: px(85) !important;
  }

  .mt-90-xs {
    margin-top: px(90) !important;
  }

  .mt-95-xs {
    margin-top: px(95) !important;
  }

  .mt-100-xs {
    margin-top: px(100) !important;
  }
  // Margin :: Right
  .mr-0-xs {
    margin-right: px(0) !important;
  }

  .mr-5-xs {
    margin-right: px(5) !important;
  }

  .mr-10-xs {
    margin-right: px(10) !important;
  }

  .mr-15-xs {
    margin-right: px(15) !important;
  }

  .mr-20-xs {
    margin-right: px(20) !important;
  }

  .mr-25-xs {
    margin-right: px(25) !important;
  }

  .mr-30-xs {
    margin-right: px(30) !important;
  }

  .mr-35-xs {
    margin-right: px(35) !important;
  }

  .mr-40-xs {
    margin-right: px(40) !important;
  }

  .mr-45-xs {
    margin-right: px(45) !important;
  }

  .mr-50-xs {
    margin-right: px(50) !important;
  }

  .mr-55-xs {
    margin-right: px(55) !important;
  }

  .mr-60-xs {
    margin-right: px(60) !important;
  }

  .mr-65-xs {
    margin-right: px(65) !important;
  }

  .mr-70-xs {
    margin-right: px(70) !important;
  }

  .mr-75-xs {
    margin-right: px(75) !important;
  }

  .mr-80-xs {
    margin-right: px(80) !important;
  }

  .mr-85-xs {
    margin-right: px(85) !important;
  }

  .mr-90-xs {
    margin-right: px(90) !important;
  }

  .mr-95-xs {
    margin-right: px(95) !important;
  }

  .mr-100-xs {
    margin-right: px(100) !important;
  }
  // Margin :: Bottom
  .mb-0-xs {
    margin-bottom: px(0) !important;
  }

  .mb-5-xs {
    margin-bottom: px(5) !important;
  }

  .mb-10-xs {
    margin-bottom: px(10) !important;
  }

  .mb-15-xs {
    margin-bottom: px(15) !important;
  }

  .mb-20-xs {
    margin-bottom: px(20) !important;
  }

  .mb-25-xs {
    margin-bottom: px(25) !important;
  }

  .mb-30-xs {
    margin-bottom: px(30) !important;
  }

  .mb-35-xs {
    margin-bottom: px(35) !important;
  }

  .mb-40-xs {
    margin-bottom: px(40) !important;
  }

  .mb-45-xs {
    margin-bottom: px(45) !important;
  }

  .mb-50-xs {
    margin-bottom: px(50) !important;
  }

  .mb-55-xs {
    margin-bottom: px(55) !important;
  }

  .mb-60-xs {
    margin-bottom: px(60) !important;
  }

  .mb-65-xs {
    margin-bottom: px(65) !important;
  }

  .mb-70-xs {
    margin-bottom: px(70) !important;
  }

  .mb-75-xs {
    margin-bottom: px(75) !important;
  }

  .mb-80-xs {
    margin-bottom: px(80) !important;
  }

  .mb-85-xs {
    margin-bottom: px(85) !important;
  }

  .mb-90-xs {
    margin-bottom: px(90) !important;
  }

  .mb-95-xs {
    margin-bottom: px(95) !important;
  }

  .mb-100-xs {
    margin-bottom: px(100) !important;
  }
  // Margin :: Left
  .ml-0-xs {
    margin-left: px(0) !important;
  }

  .ml-5-xs {
    margin-left: px(5) !important;
  }

  .ml-10-xs {
    margin-left: px(10) !important;
  }

  .ml-15-xs {
    margin-left: px(15) !important;
  }

  .ml-20-xs {
    margin-left: px(20) !important;
  }

  .ml-25-xs {
    margin-left: px(25) !important;
  }

  .ml-30-xs {
    margin-left: px(30) !important;
  }

  .ml-35-xs {
    margin-left: px(35) !important;
  }

  .ml-40-xs {
    margin-left: px(40) !important;
  }

  .ml-45-xs {
    margin-left: px(45) !important;
  }

  .ml-50-xs {
    margin-left: px(50) !important;
  }

  .ml-55-xs {
    margin-left: px(55) !important;
  }

  .ml-60-xs {
    margin-left: px(60) !important;
  }

  .ml-65-xs {
    margin-left: px(65) !important;
  }

  .ml-70-xs {
    margin-left: px(70) !important;
  }

  .ml-75-xs {
    margin-left: px(75) !important;
  }

  .ml-80-xs {
    margin-left: px(80) !important;
  }

  .ml-85-xs {
    margin-left: px(85) !important;
  }

  .ml-90-xs {
    margin-left: px(90) !important;
  }

  .ml-95-xs {
    margin-left: px(95) !important;
  }

  .ml-100-xs {
    margin-left: px(100) !important;
  }
  // Padding :: All
  .pa-0-xs {
    padding: px(0) !important;
  }

  .pa-5-xs {
    padding: px(5) !important;
  }

  .pa-10-xs {
    padding: px(10) !important;
  }

  .pa-15-xs {
    padding: px(15) !important;
  }

  .pa-20-xs {
    padding: px(20) !important;
  }

  .pa-25-xs {
    padding: px(25) !important;
  }

  .pa-30-xs {
    padding: px(30) !important;
  }

  .pa-35-xs {
    padding: px(35) !important;
  }

  .pa-40-xs {
    padding: px(40) !important;
  }

  .pa-45-xs {
    padding: px(45) !important;
  }

  .pa-50-xs {
    padding: px(50) !important;
  }

  .pa-55-xs {
    padding: px(55) !important;
  }

  .pa-60-xs {
    padding: px(60) !important;
  }

  .pa-65-xs {
    padding: px(65) !important;
  }

  .pa-70-xs {
    padding: px(70) !important;
  }

  .pa-75-xs {
    padding: px(75) !important;
  }

  .pa-80-xs {
    padding: px(80) !important;
  }

  .pa-85-xs {
    padding: px(85) !important;
  }

  .pa-90-xs {
    padding: px(90) !important;
  }

  .pa-95-xs {
    padding: px(95) !important;
  }

  .pa-100-xs {
    padding: px(100) !important;
  }
  // Padding :: Vertical
  .pv-0-xs {
    padding-top: px(0) !important;
    padding-bottom: px(0) !important;
  }

  .pv-5-xs {
    padding-top: px(5) !important;
    padding-bottom: px(5) !important;
  }

  .pv-10-xs {
    padding-top: px(10) !important;
    padding-bottom: px(10) !important;
  }

  .pv-15-xs {
    padding-top: px(15) !important;
    padding-bottom: px(15) !important;
  }

  .pv-20-xs {
    padding-top: px(20) !important;
    padding-bottom: px(20) !important;
  }

  .pv-25-xs {
    padding-top: px(25) !important;
    padding-bottom: px(25) !important;
  }

  .pv-30-xs {
    padding-top: px(30) !important;
    padding-bottom: px(30) !important;
  }

  .pv-35-xs {
    padding-top: px(35) !important;
    padding-bottom: px(35) !important;
  }

  .pv-40-xs {
    padding-top: px(40) !important;
    padding-bottom: px(40) !important;
  }

  .pv-45-xs {
    padding-top: px(45) !important;
    padding-bottom: px(45) !important;
  }

  .pv-50-xs {
    padding-top: px(50) !important;
    padding-bottom: px(50) !important;
  }

  .pv-55-xs {
    padding-top: px(55) !important;
    padding-bottom: px(55) !important;
  }

  .pv-60-xs {
    padding-top: px(60) !important;
    padding-bottom: px(60) !important;
  }

  .pv-65-xs {
    padding-top: px(65) !important;
    padding-bottom: px(65) !important;
  }

  .pv-70-xs {
    padding-top: px(70) !important;
    padding-bottom: px(70) !important;
  }

  .pv-75-xs {
    padding-top: px(75) !important;
    padding-bottom: px(75) !important;
  }

  .pv-80-xs {
    padding-top: px(80) !important;
    padding-bottom: px(80) !important;
  }

  .pv-85-xs {
    padding-top: px(85) !important;
    padding-bottom: px(85) !important;
  }

  .pv-90-xs {
    padding-top: px(90) !important;
    padding-bottom: px(90) !important;
  }

  .pv-95-xs {
    padding-top: px(95) !important;
    padding-bottom: px(95) !important;
  }

  .pv-100-xs {
    padding-top: px(100) !important;
    padding-bottom: px(100) !important;
  }
  // Padding :: Horizontal
  .ph-0-xs {
    padding-left: px(0) !important;
    padding-right: px(0) !important;
  }

  .ph-5-xs {
    padding-left: px(5) !important;
    padding-right: px(5) !important;
  }

  .ph-10-xs {
    padding-left: px(10) !important;
    padding-right: px(10) !important;
  }

  .ph-15-xs {
    padding-left: px(15) !important;
    padding-right: px(15) !important;
  }

  .ph-20-xs {
    padding-left: px(20) !important;
    padding-right: px(20) !important;
  }

  .ph-25-xs {
    padding-left: px(25) !important;
    padding-right: px(25) !important;
  }

  .ph-30-xs {
    padding-left: px(30) !important;
    padding-right: px(30) !important;
  }

  .ph-35-xs {
    padding-left: px(35) !important;
    padding-right: px(35) !important;
  }

  .ph-40-xs {
    padding-left: px(40) !important;
    padding-right: px(40) !important;
  }

  .ph-45-xs {
    padding-left: px(45) !important;
    padding-right: px(45) !important;
  }

  .ph-50-xs {
    padding-left: px(50) !important;
    padding-right: px(50) !important;
  }

  .ph-55-xs {
    padding-left: px(55) !important;
    padding-right: px(55) !important;
  }

  .ph-60-xs {
    padding-left: px(60) !important;
    padding-right: px(60) !important;
  }

  .ph-65-xs {
    padding-left: px(65) !important;
    padding-right: px(65) !important;
  }

  .ph-70-xs {
    padding-left: px(70) !important;
    padding-right: px(70) !important;
  }

  .ph-75-xs {
    padding-left: px(75) !important;
    padding-right: px(75) !important;
  }

  .ph-80-xs {
    padding-left: px(80) !important;
    padding-right: px(80) !important;
  }

  .ph-85-xs {
    padding-left: px(85) !important;
    padding-right: px(85) !important;
  }

  .ph-90-xs {
    padding-left: px(90) !important;
    padding-right: px(90) !important;
  }

  .ph-95-xs {
    padding-left: px(95) !important;
    padding-right: px(95) !important;
  }

  .ph-100-xs {
    padding-left: px(100) !important;
    padding-right: px(100) !important;
  }
  // Padding :: Top
  .pt-0-xs {
    padding-top: px(0) !important;
  }

  .pt-5-xs {
    padding-top: px(5) !important;
  }

  .pt-10-xs {
    padding-top: px(10) !important;
  }

  .pt-15-xs {
    padding-top: px(15) !important;
  }

  .pt-20-xs {
    padding-top: px(20) !important;
  }

  .pt-25-xs {
    padding-top: px(25) !important;
  }

  .pt-30-xs {
    padding-top: px(30) !important;
  }

  .pt-35-xs {
    padding-top: px(35) !important;
  }

  .pt-40-xs {
    padding-top: px(40) !important;
  }

  .pt-45-xs {
    padding-top: px(45) !important;
  }

  .pt-50-xs {
    padding-top: px(50) !important;
  }

  .pt-55-xs {
    padding-top: px(55) !important;
  }

  .pt-60-xs {
    padding-top: px(60) !important;
  }

  .pt-65-xs {
    padding-top: px(65) !important;
  }

  .pt-70-xs {
    padding-top: px(70) !important;
  }

  .pt-75-xs {
    padding-top: px(75) !important;
  }

  .pt-80-xs {
    padding-top: px(80) !important;
  }

  .pt-85-xs {
    padding-top: px(85) !important;
  }

  .pt-90-xs {
    padding-top: px(90) !important;
  }

  .pt-95-xs {
    padding-top: px(95) !important;
  }

  .pt-100-xs {
    padding-top: px(100) !important;
  }
  // Padding :: Right
  .pr-0-xs {
    padding-right: px(0) !important;
  }

  .pr-5-xs {
    padding-right: px(5) !important;
  }

  .pr-10-xs {
    padding-right: px(10) !important;
  }

  .pr-15-xs {
    padding-right: px(15) !important;
  }

  .pr-20-xs {
    padding-right: px(20) !important;
  }

  .pr-25-xs {
    padding-right: px(25) !important;
  }

  .pr-30-xs {
    padding-right: px(30) !important;
  }

  .pr-35-xs {
    padding-right: px(35) !important;
  }

  .pr-40-xs {
    padding-right: px(40) !important;
  }

  .pr-45-xs {
    padding-right: px(45) !important;
  }

  .pr-50-xs {
    padding-right: px(50) !important;
  }

  .pr-55-xs {
    padding-right: px(55) !important;
  }

  .pr-60-xs {
    padding-right: px(60) !important;
  }

  .pr-65-xs {
    padding-right: px(65) !important;
  }

  .pr-70-xs {
    padding-right: px(70) !important;
  }

  .pr-75-xs {
    padding-right: px(75) !important;
  }

  .pr-80-xs {
    padding-right: px(80) !important;
  }

  .pr-85-xs {
    padding-right: px(85) !important;
  }

  .pr-90-xs {
    padding-right: px(90) !important;
  }

  .pr-95-xs {
    padding-right: px(95) !important;
  }

  .pr-100-xs {
    padding-right: px(100) !important;
  }
  // Padding :: Bottom
  .pb-0-xs {
    padding-bottom: px(0) !important;
  }

  .pb-5-xs {
    padding-bottom: px(5) !important;
  }

  .pb-10-xs {
    padding-bottom: px(10) !important;
  }

  .pb-15-xs {
    padding-bottom: px(15) !important;
  }

  .pb-20-xs {
    padding-bottom: px(20) !important;
  }

  .pb-25-xs {
    padding-bottom: px(25) !important;
  }

  .pb-30-xs {
    padding-bottom: px(30) !important;
  }

  .pb-35-xs {
    padding-bottom: px(35) !important;
  }

  .pb-40-xs {
    padding-bottom: px(40) !important;
  }

  .pb-45-xs {
    padding-bottom: px(45) !important;
  }

  .pb-50-xs {
    padding-bottom: px(50) !important;
  }

  .pb-55-xs {
    padding-bottom: px(55) !important;
  }

  .pb-60-xs {
    padding-bottom: px(60) !important;
  }

  .pb-65-xs {
    padding-bottom: px(65) !important;
  }

  .pb-70-xs {
    padding-bottom: px(70) !important;
  }

  .pb-75-xs {
    padding-bottom: px(75) !important;
  }

  .pb-80-xs {
    padding-bottom: px(80) !important;
  }

  .pb-85-xs {
    padding-bottom: px(85) !important;
  }

  .pb-90-xs {
    padding-bottom: px(90) !important;
  }

  .pb-95-xs {
    padding-bottom: px(95) !important;
  }

  .pb-100-xs {
    padding-bottom: px(100) !important;
  }
  // Padding :: Left
  .pl-0-xs {
    padding-left: px(0) !important;
  }

  .pl-5-xs {
    padding-left: px(5) !important;
  }

  .pl-10-xs {
    padding-left: px(10) !important;
  }

  .pl-15-xs {
    padding-left: px(15) !important;
  }

  .pl-20-xs {
    padding-left: px(20) !important;
  }

  .pl-25-xs {
    padding-left: px(25) !important;
  }

  .pl-30-xs {
    padding-left: px(30) !important;
  }

  .pl-35-xs {
    padding-left: px(35) !important;
  }

  .pl-40-xs {
    padding-left: px(40) !important;
  }

  .pl-45-xs {
    padding-left: px(45) !important;
  }

  .pl-50-xs {
    padding-left: px(50) !important;
  }

  .pl-55-xs {
    padding-left: px(55) !important;
  }

  .pl-60-xs {
    padding-left: px(60) !important;
  }

  .pl-65-xs {
    padding-left: px(65) !important;
  }

  .pl-70-xs {
    padding-left: px(70) !important;
  }

  .pl-75-xs {
    padding-left: px(75) !important;
  }

  .pl-80-xs {
    padding-left: px(80) !important;
  }

  .pl-85-xs {
    padding-left: px(85) !important;
  }

  .pl-90-xs {
    padding-left: px(90) !important;
  }

  .pl-95-xs {
    padding-left: px(95) !important;
  }

  .pl-100-xs {
    padding-left: px(100) !important;
  }
}
